import axios from 'axios';
import { supabase } from '../utils/supabaseClient';

const projectConnectionService = {
  // Get all projects with their connection status
  async getAllProjects() {
    try {
      // Fetch projects from both API and Supabase
      const [{ data: notionProjects }, { data: supabaseProjects, error }] = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/api/projects`),
        supabase.from('projects').select('*')
      ]);

      if (error) throw error;

      // Create a map of existing Supabase projects for faster lookup
      const supabaseProjectMap = new Map(
        (supabaseProjects || []).map(project => [project.notion_project_id, project])
      );

      // Combine the data
      return notionProjects.map(project => ({
        ...project,
        supabaseData: supabaseProjectMap.get(project.id) || null,
        isConnected: supabaseProjectMap.has(project.id)
      }));
    } catch (error) {
      console.error('Error fetching all projects:', error);
      throw error;
    }
  },

  // Create a new project connection in Supabase
  async createProjectConnection(notionProject) {
    try {
      let clientId = null;
      if (notionProject.client) {
        const { data: existingClients, error: clientLookupError } = await supabase
          .from('clients')
          .select('id')
          .eq('notion_client_id', notionProject.client.id);

        if (clientLookupError) throw clientLookupError;

        if (existingClients && existingClients.length > 0) {
          clientId = existingClients[0].id;
        } else {
          const { data: newClient, error: clientError } = await supabase
            .from('clients')
            .insert({
              name: notionProject.client.name,
              notion_client_id: notionProject.client.id,
              created_at: new Date(),
              updated_at: new Date()
            })
            .select()
            .single();

          if (clientError) throw clientError;
          clientId = newClient.id;
        }
      }

      const { data, error } = await supabase
        .from('projects')
        .insert({
          title: notionProject.name,
          notion_project_id: notionProject.id,
          notion_client_id: notionProject.client?.id || null,
          client_id: clientId,
          status: notionProject.status,
          appetite: notionProject.appetite,
          is_active: true
        })
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error in createProjectConnection:', error);
      throw error;
    }
  },

  // Remove a project connection from Supabase
  async removeProjectConnection(notionProjectId) {
    try {
      const { error } = await supabase
        .from('projects')
        .delete()
        .match({ notion_project_id: notionProjectId });

      if (error) throw error;
    } catch (error) {
      console.error('Error removing project connection:', error);
      throw error;
    }
  },

  // Update a project connection in Supabase
  async updateProjectConnection(notionProjectId, updateData) {
    try {
      const { data, error } = await supabase
        .from('projects')
        .update(updateData)
        .match({ notion_project_id: notionProjectId })
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error updating project connection:', error);
      throw error;
    }
  },

  // Get a single project connection by Notion ID
  async getProjectConnection(notionProjectId) {
    try {
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .eq('notion_project_id', notionProjectId)
        .single();

      if (error && error.code !== 'PGRST116') throw error; // PGRST116 is "not found"
      return data;
    } catch (error) {
      console.error('Error fetching project connection:', error);
      throw error;
    }
  },

  // Update the getSupabaseProjects method
  async getSupabaseProjects() {
    try {
      const { data, error } = await supabase
        .from('projects')
        .select(`
          *,
          clients:client_id (
            id,
            name,
            notion_client_id
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      
      return { 
        data: data.map(project => ({
          ...project,
          client_name: project.clients?.name || 'No Client',
          toggl_id: project.toggl_id
        }))
      };
    } catch (error) {
      console.error('Error fetching Supabase projects:', error);
      throw error;
    }
  },

  // Add this new method to the service
  async syncProjectConnection(notionProject) {
    try {
      // First, update client if needed
      let clientId = null;
      if (notionProject.client) {
        // Try to find existing client
        const { data: existingClient } = await supabase
          .from('clients')
          .select('id')
          .eq('notion_client_id', notionProject.client.id)
          .single();

        if (existingClient) {
          clientId = existingClient.id;
          // Update client details
          await supabase
            .from('clients')
            .update({
              name: notionProject.client.name,
              updated_at: new Date()
            })
            .eq('id', clientId);
        } else {
          // Create new client if it doesn't exist
          const { data: newClient, error: clientError } = await supabase
            .from('clients')
            .insert({
              name: notionProject.client.name,
              notion_client_id: notionProject.client.id,
              created_at: new Date(),
              updated_at: new Date()
            })
            .select()
            .single();

          if (clientError) throw clientError;
          clientId = newClient.id;
        }
      }

      // Then update the project
      const { data, error } = await supabase
        .from('projects')
        .update({
          title: notionProject.name,
          client_id: clientId,
          status: notionProject.status,
          appetite: notionProject.appetite,
          updated_at: new Date()
        })
        .eq('notion_project_id', notionProject.id)
        .select()
        .single();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error('Error syncing project connection:', error);
      throw error;
    }
  },
};

export default projectConnectionService; 