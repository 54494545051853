import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';

const MonthSelector = ({ selectedDate, onDateChange }) => {
  const formatMonth = (date) => {
    return date.toLocaleString('default', { 
      month: 'long',
      year: 'numeric'
    });
  };

  const handlePreviousMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() - 1);
    onDateChange(newDate);
  };

  const handleNextMonth = () => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + 1);
    onDateChange(newDate);
  };

  const isCurrentMonth = (date) => {
    const now = new Date();
    return date.getMonth() === now.getMonth() && 
           date.getFullYear() === now.getFullYear();
  };

  return (
    <div className="flex items-center">
      <button
        onClick={handlePreviousMonth}
        className="p-1 rounded-full hover:bg-gray-100"
      >
        <ChevronLeftIcon className="h-5 w-5" />
      </button>
      <div className="w-36 text-center">
        <span className={`text-sm font-medium ${isCurrentMonth(selectedDate) ? 'text-blue-600' : ''}`}>
          {formatMonth(selectedDate)}
        </span>
      </div>
      <button
        onClick={handleNextMonth}
        className="p-1 rounded-full hover:bg-gray-100"
      >
        <ChevronRightIcon className="h-5 w-5" />
      </button>
    </div>
  );
};

export default MonthSelector; 