import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { BriefcaseIcon, CalendarIcon, CheckIcon, ChevronDownIcon, CurrencyDollarIcon, PencilIcon, MapPinIcon, ClipboardIcon, DocumentTextIcon, LinkIcon, CheckCircleIcon, ExclamationTriangleIcon, ChevronRightIcon, HomeIcon, TrashIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { Menu } from '@headlessui/react'
import PercentageSlider from '../components/PercentageSlider';
import { supabase } from '../utils/supabaseClient';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const WEEKLY_RATE = 7450;

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

const formatPercentage = (value) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value);
};

const StatusBar = ({ currentStatus }) => {
  const stages = ['Shaping', 'Dev Ready', 'In Progress', 'In Review', 'Completed'];
  const currentIndex = stages.indexOf(currentStatus);

  return (
    <div className="flex justify-between items-center mb-8">
      {stages.map((stage, index) => (
        <div key={stage} className="flex flex-col items-center">
          <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
            index <= currentIndex ? 'bg-green-500' : 'bg-gray-200'
          }`}>
            <CheckCircleIcon className={`w-6 h-6 ${
              index <= currentIndex ? 'text-white' : 'text-gray-400'
            }`} />
          </div>
          <span className="text-sm mt-2">{stage}</span>
        </div>
      ))}
    </div>
  );
};

const HoursBar = ({ plannedHours, actualHours }) => (
  <div className="relative w-full h-4 bg-gray-200 rounded-full overflow-hidden">
    <div 
      className="absolute top-0 left-0 h-full bg-blue-500" 
      style={{ width: `${(actualHours / plannedHours) * 100}%` }}
    ></div>
    <div 
      className="absolute top-0 right-0 h-full border-l-2 border-red-500" 
      style={{ left: `${(plannedHours / Math.max(plannedHours, actualHours)) * 100}%` }}
    ></div>
  </div>
);

const Breadcrumbs = ({ clientName, projectName, filterParams }) => {
  const pages = [
    { name: 'Projects', href: `/projects${filterParams}`, current: false },
    { name: clientName, href: `/projects?client=${encodeURIComponent(clientName)}`, current: false },
    { name: projectName, current: true },
  ];

  return (
    <nav aria-label="Breadcrumb" className="flex mb-4">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link to="/projects" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Projects</span>
            </Link>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {page.current ? (
                <span className="ml-4 text-sm font-medium text-gray-500" aria-current="page">
                  {page.name}
                </span>
              ) : (
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

const ProjectDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const [project, setProject] = useState(location.state?.project || null);
  const filterParams = location.state?.filterParams || '';

  useEffect(() => {
    if (!project) {
      // Fetch project data if not available in location state
      const fetchProject = async () => {
        try {
          const response = await fetch(`/api/projects/${id}`);
          const data = await response.json();
          setProject(data);
        } catch (error) {
          console.error('Error fetching project:', error);
        }
      };
      fetchProject();
    }
  }, [id, project]);

  const [projectDuration, setProjectDuration] = useState('4');
  const [teamMembers, setTeamMembers] = useState([]);
  const [projectStatus, setProjectStatus] = useState('In Progress');
  const [roleDistribution, setRoleDistribution] = useState([20, 50, 30]);
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    if (project) {
      // Extract the number from the appetite string and set it as projectDuration
      const durationMatch = project.appetite?.match(/(\d+)/);
      if (durationMatch) {
        setProjectDuration(durationMatch[1]);
      } else {
        setProjectDuration('4'); // Default to 4 if no match found
      }
      setProjectStatus(project.status || 'In Progress');
    }
  }, [project]);

  const handleInputChange = (id, field, value) => {
    setTeamMembers(prevMembers =>
      prevMembers.map(member =>
        member.id === id ? { ...member, [field]: field === 'rate' ? parseFloat(value) || 0 : parseInt(value) || 0 } : member
      )
    );
  };

  const projectBudget = useMemo(() => parseInt(projectDuration) * WEEKLY_RATE, [projectDuration]);
  const totalPlannedHours = useMemo(() => teamMembers.reduce((sum, member) => sum + member.plannedHours, 0), [teamMembers]);
  const totalActualHours = useMemo(() => teamMembers.reduce((sum, member) => sum + member.actualHours, 0), [teamMembers]);
  const totalPlannedCost = useMemo(() => teamMembers.reduce((sum, member) => sum + member.rate * member.plannedHours, 0), [teamMembers]);
  const totalActualCost = useMemo(() => teamMembers.reduce((sum, member) => sum + member.rate * member.actualHours, 0), [teamMembers]);
  const plannedProfitMargin = useMemo(() => (projectBudget - totalPlannedCost) / projectBudget, [projectBudget, totalPlannedCost]);
  const actualProfitMargin = useMemo(() => (projectBudget - totalActualCost) / projectBudget, [projectBudget, totalActualCost]);

  const maxProjectHours = parseInt(projectDuration) * 40; // Assuming 40 hours per week

  useEffect(() => {
    if (project && project.team) {
      const initialTeam = project.team.map((member, index) => ({
        id: index + 1,
        name: member.name,
        role: member.role,
        rate: 100, // Default rate, you might want to adjust this
        plannedHours: 0,
        actualHours: 0,
        imageUrl: member.avatarUrl || '/placeholder.svg?height=40&width=40'
      }));
      setTeamMembers(initialTeam);
    }
  }, [project]);

  useEffect(() => {
    const newTotalHours = maxProjectHours;
    setTeamMembers(prevMembers => prevMembers.map((member, index) => ({
      ...member,
      plannedHours: Math.round(newTotalHours * roleDistribution[index] / 100)
    })));
  }, [projectDuration, roleDistribution, maxProjectHours]);

  const handleOpenInNotion = () => {
    if (project && project.url) {
      window.open(project.url, '_blank');
    } else {
      console.error('Notion URL is missing for this project');
      // You might want to show an error message to the user here
    }
  };

  const [payments, setPayments] = useState([]);
  const [newPayment, setNewPayment] = useState({
    notion_id: '',
    amount: '',
    date: new Date().toISOString().split('T')[0],
    recipient: '',
    payment_method: 'Invoice',
    invoice_link: '',
    payment_type: 'credit', // Add this line
  });
  const [isLoadingPayments, setIsLoadingPayments] = useState(false);
  const [paymentError, setPaymentError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingPaymentId, setEditingPaymentId] = useState(null);
  const [deletingPaymentId, setDeletingPaymentId] = useState(null);

  const fetchPayments = async () => {
    if (project?.id) {
      setIsLoadingPayments(true);
      setPaymentError(null);
      try {
        const { data, error } = await supabase
          .from('payments')
          .select('*')
          .eq('notion_id', project.id)
          .order('date', { ascending: true }); // This line orders the payments
        
        if (error) throw error;
        
        setPayments(data);
      } catch (err) {
        setPaymentError('Failed to load payments');
        console.error(err);
      } finally {
        setIsLoadingPayments(false);
      }
    }
  };

  // Update the useEffect to use the fetchPayments function
  useEffect(() => {
    fetchPayments();
  }, [project]);

  const openModal = (payment = null) => {
    if (payment) {
      setNewPayment({
        ...payment,
        date: new Date(payment.date).toISOString().split('T')[0],
      });
      setIsEditing(true);
      setEditingPaymentId(payment.id);
    } else {
      setNewPayment({
        notion_id: project.id,
        amount: '',
        date: new Date().toISOString().split('T')[0],
        recipient: '',
        payment_method: 'Invoice',
        invoice_link: '',
        payment_type: 'credit', // Add this line
      });
      setIsEditing(false);
      setEditingPaymentId(null);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  const handleSubmitPayment = async (e) => {
    e.preventDefault();
    setIsLoadingPayments(true);
    setPaymentError(null);
    try {
      let error;
      if (isEditing) {
        const { error: updateError } = await supabase
          .from('payments')
          .update({ 
            ...newPayment, 
            amount: parseFloat(newPayment.amount),
            invoice_link: newPayment.invoice_link || null 
          })
          .eq('id', editingPaymentId);
        error = updateError;
      } else {
        const { error: insertError } = await supabase
          .from('payments')
          .insert([{ 
            ...newPayment, 
            amount: parseFloat(newPayment.amount),
            invoice_link: newPayment.invoice_link || null 
          }]);
        error = insertError;
      }

      if (error) throw error;

      // Refresh the payments data after successful update or insert
      await fetchPayments();
      closeModal();
    } catch (err) {
      setPaymentError(`Failed to ${isEditing ? 'update' : 'add'} payment`);
      console.error(err);
    } finally {
      setIsLoadingPayments(false);
    }
  };

  const handlePaymentInputChange = (e) => {
    const { name, value } = e.target;
    setNewPayment({ ...newPayment, [name]: value });
  };

  const [teamUsers, setTeamUsers] = useState([]);

  // New useEffect to fetch all users with role 'team'
  useEffect(() => {
    const fetchTeamUsers = async () => {
      try {
        const { data, error } = await supabase
          .from('users')
          .select('id, name')
          .eq('role', 'team');

        if (error) throw error;

        setTeamUsers(data);
      } catch (err) {
        console.error('Error fetching team users:', err);
      }
    };

    fetchTeamUsers();
  }, []);

  const handleDeletePayment = async (paymentId) => {
    setIsLoadingPayments(true);
    setPaymentError(null);
    try {
      const { error } = await supabase
        .from('payments')
        .delete()
        .eq('id', paymentId);

      if (error) throw error;

      await fetchPayments();
      setDeletingPaymentId(null);
    } catch (err) {
      setPaymentError('Failed to delete payment');
      console.error(err);
    } finally {
      setIsLoadingPayments(false);
    }
  };

  const tableRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setDeletingPaymentId(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const fetchClients = async () => {
    try {
      const { data, error } = await supabase
        .from('clients')  // Adjust this to match your actual table name
        .select('id, name');

      if (error) throw error;

      setClientList(data);
    } catch (err) {
      console.error('Error fetching clients:', err);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handlePaymentTypeChange = (type) => {
    setNewPayment(prev => ({
      ...prev,
      payment_type: type,
      recipient: type === 'credit' ? project.client?.name || '' : ''
    }));
  };

  // Add this function to calculate the total balance
  const calculateTotalBalance = (payments) => {
    return payments.reduce((total, payment) => {
      return total + (payment.payment_type === 'credit' ? payment.amount : -payment.amount);
    }, 0);
  };

  // In the ProjectDetail component, add this before the return statement
  const totalBalance = calculateTotalBalance(payments);

  const [userRole, setUserRole] = useState(null);

  const fetchUserRole = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('users')
          .select('role')
          .eq('id', user.id)
          .single();

        if (error) throw error;

        setUserRole(data.role);
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };

  useEffect(() => {
    fetchUserRole();
  }, []);

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <Breadcrumbs 
        clientName={project.client?.name || ''} 
        projectName={project.name}
        filterParams={filterParams}
      />
      
      <div className="lg:flex lg:items-center lg:justify-between mb-8">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {project.name}
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <BriefcaseIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" />
              {project.appetite}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CalendarIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" />
              Deadline: {new Date(project.endDate).toLocaleDateString()}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <MapPinIcon aria-hidden="true" className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" />
              Client: {project.client?.name || 'Unknown Client'}
            </div>
          </div>
        </div>
        <div className="mt-5 flex lg:ml-4 lg:mt-0">
          <span className="hidden sm:block">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <PencilIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" />
              Edit
            </button>
          </span>

          <span className="ml-3 hidden sm:block">
            <button
              type="button"
              className="inline-flex items-center rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-600 hover:bg-gray-800"
              onClick={handleOpenInNotion}
            >
              <LinkIcon aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-white" />
              Open in Notion
            </button>
          </span>

          {/* Dropdown */}
          <Menu as="div" className="relative ml-3 sm:hidden">
            <Menu.Button className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400">
              More
              <ChevronDownIcon aria-hidden="true" className="-mr-1 ml-1.5 h-5 w-5 text-gray-400" />
            </Menu.Button>

            <Menu.Items
              transition
              className="absolute right-0 z-10 -mr-1 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : 'text-gray-700'}`}
                  >
                    Edit
                  </a>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <a
                    href="#"
                    className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100' : 'text-gray-700'}`}
                  >
                    View
                  </a>
                )}
              </Menu.Item>
            </Menu.Items>
          </Menu>
        </div>
      </div>

      <StatusBar currentStatus={projectStatus} />

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <div className="md:col-span-2 bg-white shadow rounded-lg p-6">
          <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-4 sm:space-y-0">
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <h2 className="text-xl font-semibold">Team Members & Hours</h2>
              <div className="w-[500px] min-w-[300px] max-w-2xl">
                <PercentageSlider 
                  percentages={roleDistribution} 
                  setPercentages={setRoleDistribution}
                />
                <div className="flex justify-between text-sm mt-2">
                  <span className="text-blue-300 font-semibold">PM {roleDistribution[0]}%</span>
                  <span className="text-blue-500 font-semibold">Developer {roleDistribution[1]}%</span>
                  <span className="text-blue-700 font-semibold">Designer {roleDistribution[2]}%</span>
                </div>
              </div>
            </div>
            <div className="text-right">
              <select 
                value={projectDuration}
                onChange={(e) => setProjectDuration(e.target.value)}
                className="border rounded p-2 mb-2"
              >
                <option value="1">1 Week</option>
                <option value="2">2 Weeks</option>
                <option value="4">4 Weeks</option>
                <option value="6">6 Weeks</option>
              </select>
              <div className="mt-2 text-2xl font-bold">{formatCurrency(projectBudget)}</div>
              <div className="text-sm text-gray-500">Total Budget</div>
            </div>
          </div>
          <div className="mt-6">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Team Member</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rate</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Planned Hours</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actual Hours</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Planned Cost</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actual Cost</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Progress</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {teamMembers.map(member => (
                  <tr key={member.id}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img className="h-10 w-10 rounded-full" src={member.imageUrl} alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{member.name}</div>
                          <div className="text-sm text-gray-500">{member.role}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="number"
                        value={member.rate}
                        onChange={(e) => handleInputChange(member.id, 'rate', e.target.value)}
                        className="w-20 border rounded p-1"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="number"
                        value={member.plannedHours}
                        onChange={(e) => handleInputChange(member.id, 'plannedHours', e.target.value)}
                        className="w-20 border rounded p-1"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <input
                        type="number"
                        value={member.actualHours}
                        onChange={(e) => handleInputChange(member.id, 'actualHours', e.target.value)}
                        className="w-20 border rounded p-1"
                      />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(member.rate * member.plannedHours)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{formatCurrency(member.rate * member.actualHours)}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <HoursBar plannedHours={member.plannedHours} actualHours={member.actualHours} />
                      <div className="text-xs text-gray-500 mt-1">
                        {member.actualHours}/{member.plannedHours} hours
                      </div>
                    </td>
                  </tr>
                ))}
                <tr className="bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap font-bold" colSpan={2}>Total</td>
                  <td className="px-6 py-4 whitespace-nowrap font-bold">
                    {totalPlannedHours}
                    {totalPlannedHours > maxProjectHours && (
                      <ExclamationTriangleIcon className="inline-block ml-2 text-yellow-500" />
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-bold">
                    {totalActualHours}
                    {totalActualHours > maxProjectHours && (
                      <ExclamationTriangleIcon className="inline-block ml-2 text-yellow-500" />
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap font-bold">{formatCurrency(totalPlannedCost)}</td>
                  <td className="px-6 py-4 whitespace-nowrap font-bold">{formatCurrency(totalActualCost)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <HoursBar plannedHours={totalPlannedHours} actualHours={totalActualHours} />
                    <div className="text-xs text-gray-500 mt-1">
                      {totalActualHours}/{totalPlannedHours} hours
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="mt-4 text-right">
              <div className="flex justify-end space-x-4">
                <div>
                  <p className="text-lg font-semibold">Planned Profit Margin: <span className={plannedProfitMargin >= 0 ? "text-green-600" : "text-red-600"}>{formatPercentage(plannedProfitMargin)}</span></p>
                  <p className="text-sm text-gray-500">
                    {formatCurrency(projectBudget)} - {formatCurrency(totalPlannedCost)} = {formatCurrency(projectBudget - totalPlannedCost)}
                  </p>
                </div>
                <div>
                  <p className="text-lg font-semibold">Actual Profit Margin: <span className={actualProfitMargin >= 0 ? "text-green-600" : "text-red-600"}>{formatPercentage(actualProfitMargin)}</span></p>
                  <p className="text-sm text-gray-500">
                    {formatCurrency(projectBudget)} - {formatCurrency(totalActualCost)} = {formatCurrency(projectBudget - totalActualCost)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {userRole === 'admin' && (
        <div className="mt-10 bg-white shadow rounded-lg p-6">
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center space-x-2">
              <h2 className="text-2xl font-bold text-gray-900">Payments</h2>
              <span className="bg-gray-100 text-gray-600 text-xs font-medium px-2 py-1 rounded-full">
                Admin only
              </span>
            </div>
            <button
              onClick={() => openModal(null)}
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Payment
            </button>
          </div>
          {isLoadingPayments && <p>Loading payments...</p>}
          {paymentError && <p className="text-red-500">{paymentError}</p>}
          {!isLoadingPayments && !paymentError && (
            <div className="overflow-x-auto">
              <div ref={tableRef}>
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Who
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Payment Method
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider w-32">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {payments.filter(payment => payment != null).map((payment) => (
                      <tr key={payment.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {payment.recipient} {/* Removed the "From: " and "To: " labels */}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {new Date(payment.date).toLocaleDateString()}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <div className="flex items-center">
                            {payment.payment_method}
                            {payment.invoice_link && (
                              <a
                                href={payment.invoice_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ml-2 text-indigo-600 hover:text-indigo-900"
                              >
                                <ArrowTopRightOnSquareIcon className="h-5 w-5" aria-hidden="true" />
                              </a>
                            )}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            payment.payment_type === 'credit' 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-red-100 text-red-800'
                          }`}>
                            {payment.payment_type === 'credit' ? '+' : '-'}{formatCurrency(Math.abs(payment.amount))}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium w-32">
                          <Menu as="div" className="relative inline-block text-left">
                            <Menu.Button className="inline-flex w-full justify-center items-center gap-2 rounded-md bg-white py-1.5 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                              Actions
                              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </Menu.Button>

                            <Menu.Items
                              className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() => openModal(payment)}
                                      className={`${
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                      } group flex w-full items-center px-4 py-2 text-sm`}
                                    >
                                      <PencilIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                      Edit
                                    </button>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <button
                                      onClick={() => {
                                        if (window.confirm('Are you sure you want to delete this payment?')) {
                                          handleDeletePayment(payment.id);
                                        }
                                      }}
                                      className={`${
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                                      } group flex w-full items-center px-4 py-2 text-sm`}
                                    >
                                      <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                      Delete
                                    </button>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Menu>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Total Balance:
                      </td>
                      <td></td> {/* Empty cell for Date column */}
                      <td></td> {/* Empty cell for Payment Method column */}
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-bold">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                          totalBalance >= 0 
                            ? 'bg-green-100 text-green-800' 
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {totalBalance >= 0 ? '+' : '-'}{formatCurrency(Math.abs(totalBalance))}
                        </span>
                      </td>
                      <td></td> {/* Empty cell for the Actions column */}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Payment Modal */}
      {userRole === 'admin' && (
        <Transition appear show={isModalOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900 mb-4"
                    >
                      {isEditing ? 'Update Payment' : 'Add New Payment'}
                    </Dialog.Title>
                    <div className="mb-6">
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Payment Type
                      </label>
                      <div className="flex space-x-4">
                        <button
                          type="button"
                          onClick={() => handlePaymentTypeChange('credit')}
                          className={`flex-1 py-2 px-4 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                            newPayment.payment_type === 'credit'
                              ? 'bg-green-600 text-white hover:bg-green-700 focus:ring-green-500'
                              : 'bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring-gray-500'
                          }`}
                        >
                          Credit
                        </button>
                        <button
                          type="button"
                          onClick={() => handlePaymentTypeChange('debit')}
                          className={`flex-1 py-2 px-4 rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                            newPayment.payment_type === 'debit'
                              ? 'bg-red-600 text-white hover:bg-red-700 focus:ring-red-500'
                              : 'bg-gray-200 text-gray-700 hover:bg-gray-300 focus:ring-gray-500'
                          }`}
                        >
                          Debit
                        </button>
                      </div>
                    </div>
                    <form onSubmit={handleSubmitPayment} className="mt-4">
                      <div className="grid grid-cols-1 gap-4">
                        <div>
                          <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                            Amount
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                              <span className="text-gray-500 text-lg">$</span>
                            </div>
                            <input
                              type="number"
                              name="amount"
                              id="amount"
                              value={newPayment.amount}
                              onChange={handlePaymentInputChange}
                              required
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-12 pr-12 py-3 text-lg border-gray-300 rounded-md"
                              placeholder="0.00"
                              step="0.01"
                            />
                            <div className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none">
                              <span className="text-gray-500 text-lg">USD</span>
                            </div>
                          </div>
                        </div>
                        {newPayment.payment_type === 'debit' && (
                          <div>
                            <label htmlFor="recipient" className="block text-sm font-medium text-gray-700">
                              To
                            </label>
                            <select
                              name="recipient"
                              id="recipient"
                              value={newPayment.recipient}
                              onChange={handlePaymentInputChange}
                              required
                              className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg"
                            >
                              <option value="">Select a team member</option>
                              {teamUsers.map((user) => (
                                <option key={user.id} value={user.name}>
                                  {user.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        <div>
                          <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                            Date
                          </label>
                          <input
                            type="date"
                            name="date"
                            id="date"
                            value={newPayment.date}
                            onChange={handlePaymentInputChange}
                            required
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-lg border-gray-300 rounded-md"
                          />
                        </div>
                        <div>
                          <label htmlFor="payment_method" className="block text-sm font-medium text-gray-700">
                            Payment Method
                          </label>
                          <select
                            name="payment_method"
                            id="payment_method"
                            value={newPayment.payment_method}
                            onChange={handlePaymentInputChange}
                            required
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-lg"
                          >
                            <option value="Invoice">Invoice</option>
                            <option value="Gusto">Gusto</option>
                            <option value="Wire">Wire</option>
                          </select>
                        </div>
                        <div>
                          <label htmlFor="invoice_link" className="block text-sm font-medium text-gray-700">
                            Invoice Link
                          </label>
                          <input
                            type="url"
                            name="invoice_link"
                            id="invoice_link"
                            value={newPayment.invoice_link}
                            onChange={handlePaymentInputChange}
                            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm text-lg border-gray-300 rounded-md py-3 px-4"
                            placeholder="https://example.com/invoice"
                          />
                        </div>
                      </div>
                      <div className="mt-4 flex justify-end">
                        <button
                          type="button"
                          className="mr-2 inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          disabled={isLoadingPayments}
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                        >
                          {isLoadingPayments ? 'Saving...' : (isEditing ? 'Update Payment' : 'Add Payment')}
                        </button>
                      </div>
                    </form>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </div>
  );
};

export default ProjectDetail;