import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import projectConnectionService from '../services/projectConnectionService';
import Loader from '../components/Loader';
import { ChevronDownIcon, CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { supabase } from '../utils/supabaseClient';
import { createPortal } from 'react-dom';

const FilterDropdown = ({ label, options, selectedOptions, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Add click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
        className="inline-flex justify-between items-center w-48 px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
      >
        <span>{label} ({selectedOptions.length || 'All'})</span>
        <ChevronDownIcon className="w-4 h-4 ml-2" />
      </button>
      
      {isOpen && (
        <div className="absolute z-10 w-48 mt-1 bg-white rounded-md shadow-lg">
          <div className="p-2 border-b border-gray-200">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onChange([]);
                setIsOpen(false);
              }}
              className="text-sm text-blue-600 hover:text-blue-800"
            >
              Clear
            </button>
          </div>
          <div className="max-h-48 overflow-y-auto">
            {options.map((option) => (
              <div
                key={option}
                className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  const newSelection = selectedOptions.includes(option)
                    ? selectedOptions.filter(item => item !== option)
                    : [...selectedOptions, option];
                  onChange(newSelection);
                }}
              >
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => {}}
                  className="h-4 w-4 text-blue-600 rounded border-gray-300"
                />
                <span className="ml-2 text-sm text-gray-700">{option}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const TabButton = ({ isActive, onClick, children }) => (
  <button
    onClick={onClick}
    className={`px-4 py-2 font-medium text-sm rounded-lg ${
      isActive 
        ? 'bg-blue-100 text-blue-700' 
        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
    }`}
  >
    {children}
  </button>
);

const CACHE_KEY = 'toggl_projects_cache';
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour

const useTogglProjects = () => {
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Check cache and fetch data
  const fetchProjects = useCallback(async (forceRefresh = false) => {
    if (!forceRefresh) {
      // Try to get from memory first
      if (projects) return projects;

      // Try to get from localStorage
      try {
        const cached = localStorage.getItem(CACHE_KEY);
        if (cached) {
          const { data, timestamp } = JSON.parse(cached);
          if (Date.now() - timestamp < CACHE_DURATION) {
            setProjects(data);
            return data;
          }
        }
      } catch (err) {
        console.error('Cache read error:', err);
      }
    }

    // Fetch fresh data
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch('/api/toggl/projects');
      if (!response.ok) throw new Error('Failed to fetch Toggl projects');
      
      const data = await response.json();
      
      // Update cache
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        data,
        timestamp: Date.now()
      }));
      
      setProjects(data);
      return data;
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  }, [projects]);

  return { projects, loading, error, fetchProjects };
};

const TogglProjectSelect = ({ projectId, currentTogglId, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const dropdownRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const { projects: togglProjects, loading, fetchProjects } = useTogglProjects();

  // Fetch projects when dropdown opens
  useEffect(() => {
    if (isOpen && !togglProjects) {
      fetchProjects().catch(err => {
        console.error('Error fetching projects:', err);
        setError(err.message);
      });
    }
  }, [isOpen, togglProjects, fetchProjects]);

  // Filter projects based on search term
  const filteredProjects = useMemo(() => {
    if (!togglProjects) return [];
    
    const searchLower = searchTerm.toLowerCase();
    return togglProjects.filter(project => 
      project.name.toLowerCase().includes(searchLower) ||
      (project.client_name && project.client_name.toLowerCase().includes(searchLower))
    );
  }, [togglProjects, searchTerm]);

  const handleSelect = async (togglProject) => {
    try {
      setIsSaving(true);
      console.log('Starting Toggl project selection...', {
        togglProject,
        projectId,
        togglId: togglProject.id.toString()
      });
      
      // First verify we can read the project
      const { data: existingProject, error: readError } = await supabase
        .from('projects')
        .select('*')
        .eq('id', projectId)
        .single();

      if (readError) {
        console.error('Error reading project:', readError);
        throw readError;
      }

      console.log('Existing project:', existingProject);

      // Then perform the update with the project name
      const { error: updateError } = await supabase
        .from('projects')
        .update({ 
          toggl_id: togglProject.id.toString(),
          toggl_project_name: togglProject.name, // Store the project name
          toggl_client_name: togglProject.client_name || null // Optionally store client name
        })
        .eq('id', projectId);

      if (updateError) {
        console.error('Update error:', updateError);
        throw updateError;
      }

      // Verify the update worked
      const { data: updatedProject, error: verifyError } = await supabase
        .from('projects')
        .select('*')
        .eq('id', projectId)
        .single();

      if (verifyError) {
        console.error('Verification error:', verifyError);
        throw verifyError;
      }

      console.log('Update successful:', {
        before: existingProject,
        after: updatedProject
      });

      setSelectedProject(togglProject);
      onSelect(togglProject.id);
      setIsOpen(false);
      setSearchTerm('');
      
    } catch (err) {
      console.error('Error in handleSelect:', err);
      setError(err.message);
      alert(`Failed to update project: ${err.message}`);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDisconnect = async (e) => {
    e.stopPropagation();
    try {
      setIsSaving(true);
      
      const { data, error } = await supabase
        .from('projects')
        .update({ toggl_id: null })
        .eq('id', projectId)
        .select('*');

      if (error) throw error;

      console.log('Disconnect successful:', data);
      setSelectedProject(null);
      onSelect(null);
      
    } catch (err) {
      console.error('Error disconnecting Toggl project:', err);
      setError(err.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="relative" ref={dropdownRef}>
      {isSaving ? (
        <div className="inline-flex items-center px-4 py-2 text-sm bg-gray-50 border border-gray-300 text-gray-500 rounded-md">
          <div className="animate-spin h-4 w-4 mr-2 border-2 border-gray-500 border-t-transparent rounded-full"></div>
          Saving...
        </div>
      ) : selectedProject || currentTogglId ? (
        <div className="flex items-center space-x-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
            className="inline-flex items-center px-4 py-2 text-sm bg-green-50 border border-green-300 text-green-700 rounded-md hover:bg-green-100 group"
          >
            <CheckCircleIcon className="w-5 h-5 mr-2 text-green-500 flex-shrink-0" />
            <div className="flex flex-col items-start overflow-hidden">
              <span className="truncate">Connected to Toggl</span>
              {selectedProject?.name && (
                <span className="text-xs text-green-600 truncate">
                  {selectedProject.name}
                  {selectedProject.client_name && ` (${selectedProject.client_name})`}
                </span>
              )}
            </div>
            <ChevronDownIcon className="w-4 h-4 ml-2 flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity" />
          </button>
          <button
            onClick={handleDisconnect}
            className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-50 rounded-md transition-colors"
            title="Disconnect Toggl project"
          >
            <XMarkIcon className="w-5 h-5" />
          </button>
        </div>
      ) : (
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex justify-between items-center w-64 px-4 py-2 text-sm bg-white border border-gray-300 rounded-md hover:bg-gray-50"
        >
          <span>Select Toggl Project</span>
          <ChevronDownIcon className="w-4 h-4 ml-2 flex-shrink-0" />
        </button>
      )}

      {isOpen && createPortal(
        <div 
          className="fixed bg-white rounded-md shadow-lg"
          style={{
            width: '24rem',
            maxHeight: '24rem',
            top: dropdownRef.current?.getBoundingClientRect().bottom + 8 || 0,
            left: dropdownRef.current?.getBoundingClientRect().left || 0,
            zIndex: 50
          }}
        >
          <div className="sticky top-0 p-2 border-b border-gray-200 bg-white">
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search projects..."
              className="w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              autoFocus
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          
          <div className="overflow-y-auto" style={{ maxHeight: '20rem' }}>
            {loading ? (
              <div className="p-4 text-center text-gray-500">Loading projects...</div>
            ) : error ? (
              <div className="p-4 text-center text-red-500">{error}</div>
            ) : filteredProjects.length === 0 ? (
              <div className="p-4 text-center text-gray-500">
                {searchTerm ? 'No matching projects' : 'No projects available'}
              </div>
            ) : (
              filteredProjects.map((project) => (
                <div
                  key={project.id}
                  className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelect(project);
                  }}
                >
                  <div className="text-sm text-gray-900">{project.name}</div>
                  {project.client_name && (
                    <div className="text-xs text-gray-500">{project.client_name}</div>
                  )}
                </div>
              ))
            )}
          </div>
        </div>,
        document.body
      )}
    </div>
  );
};

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

const ProjectConnections = () => {
  const [activeTab, setActiveTab] = useState('tools');
  const [projects, setProjects] = useState([]);
  const [supabaseProjects, setSupabaseProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const [error, setError] = useState(null);
  
  // Filter states
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedConnectionStates, setSelectedConnectionStates] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearch = useDebounce(searchQuery, 300);

  const filteredProjectsList = useMemo(() => {
    if (!projects) return [];
    
    const searchLower = debouncedSearch.toLowerCase();
    let filtered = [...projects];

    // Apply search filter
    if (searchLower) {
      filtered = filtered.filter(project => 
        project.name?.toLowerCase().includes(searchLower) ||
        project.client?.name?.toLowerCase().includes(searchLower)
      );
    }

    // Apply other filters
    if (selectedStatuses.length > 0) {
      filtered = filtered.filter(project => selectedStatuses.includes(project.status));
    }

    if (selectedClients.length > 0) {
      filtered = filtered.filter(project => 
        project.client && selectedClients.includes(project.client.name)
      );
    }

    if (selectedConnectionStates.length > 0) {
      filtered = filtered.filter(project => {
        const connectionState = project.isConnected ? 'Connected' : 'Not Connected';
        return selectedConnectionStates.includes(connectionState);
      });
    }

    return filtered;
  }, [projects, debouncedSearch, selectedStatuses, selectedClients, selectedConnectionStates]);

  const renderSearchBar = () => (
    <div className="relative w-96">
      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search projects or clients..."
        className="w-full px-4 py-2 pl-10 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
      />
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <svg
          className="h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      {searchQuery && (
        <button
          onClick={() => setSearchQuery('')}
          className="absolute inset-y-0 right-0 pr-3 flex items-center"
        >
          <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" />
        </button>
      )}
    </div>
  );

  const fetchProjects = async () => {
    try {
      setLoading(true);
      const projectConnections = await projectConnectionService.getAllProjects();
      setProjects(projectConnections);
    } catch (err) {
      setError('Failed to fetch projects: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchSupabaseProjects = async () => {
    try {
      const { data, error } = await projectConnectionService.getSupabaseProjects();
      if (error) throw error;
      setSupabaseProjects(data);
    } catch (err) {
      setError('Failed to fetch Supabase projects: ' + err.message);
    }
  };

  useEffect(() => {
    if (activeTab === 'import') {
      fetchProjects();
    } else {
      fetchSupabaseProjects();
    }
  }, [activeTab]);

  useEffect(() => {
    let result = [...projects];

    if (selectedStatuses.length > 0) {
      result = result.filter(project => selectedStatuses.includes(project.status));
    }

    if (selectedClients.length > 0) {
      result = result.filter(project => 
        project.client && selectedClients.includes(project.client.name)
      );
    }

    if (selectedConnectionStates.length > 0) {
      result = result.filter(project => {
        const connectionState = project.isConnected ? 'Connected' : 'Not Connected';
        return selectedConnectionStates.includes(connectionState);
      });
    }

    setFilteredProjects(result);
  }, [projects, selectedStatuses, selectedClients, selectedConnectionStates]);

  const handleConnect = async (project) => {
    try {
      setLoadingStates(prev => ({ ...prev, [project.id]: true }));
      await projectConnectionService.createProjectConnection(project);
      
      setProjects(currentProjects => 
        currentProjects.map(p => 
          p.id === project.id 
            ? { ...p, isConnected: true, supabaseData: { notion_project_id: project.id } }
            : p
        )
      );
    } catch (err) {
      console.error('Connection error:', err);
      setError('Failed to connect project: ' + err.message);
    } finally {
      setLoadingStates(prev => ({ ...prev, [project.id]: false }));
    }
  };

  const handleSync = async (project) => {
    try {
      setLoadingStates(prev => ({ ...prev, [project.id]: true }));
      await projectConnectionService.syncProjectConnection(project);
      
      setProjects(currentProjects => 
        currentProjects.map(p => 
          p.id === project.id 
            ? { ...p, isConnected: true }
            : p
        )
      );
    } catch (err) {
      console.error('Sync error:', err);
      setError('Failed to sync project: ' + err.message);
    } finally {
      setLoadingStates(prev => ({ ...prev, [project.id]: false }));
    }
  };

  // Get unique options for filters
  const getUniqueStatuses = () => [...new Set(projects.map(p => p.status))].filter(Boolean);
  const getUniqueClients = () => [...new Set(projects.map(p => p.client?.name))].filter(Boolean);
  const connectionStates = ['Connected', 'Not Connected'];

  if (loading) return <Loader />;
  if (error) return <div className="text-red-500 p-4">{error}</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Project Connections</h1>
        <div className="text-sm text-gray-500">
          {activeTab === 'import' && 
            `${filteredProjects.filter(p => p.isConnected).length} of ${filteredProjects.length} projects connected`
          }
        </div>
      </div>

      {/* Tabs */}
      <div className="flex space-x-4 mb-6 border-b">
        <TabButton 
          isActive={activeTab === 'tools'} 
          onClick={() => setActiveTab('tools')}
        >
          1. Link Tools
        </TabButton>
        <TabButton 
          isActive={activeTab === 'import'} 
          onClick={() => setActiveTab('import')}
        >
          2. Import from Notion
        </TabButton>
      </div>

      {activeTab === 'import' ? (
        <>
          {/* Filters */}
          <div className="flex items-center justify-between mb-6">
            {/* Search on the left */}
            {renderSearchBar()}
            
            {/* Filters on the right */}
            <div className="flex gap-4">
              <FilterDropdown
                label="Status"
                options={getUniqueStatuses()}
                selectedOptions={selectedStatuses}
                onChange={setSelectedStatuses}
              />
              <FilterDropdown
                label="Client"
                options={getUniqueClients()}
                selectedOptions={selectedClients}
                onChange={setSelectedClients}
              />
              <FilterDropdown
                label="Connection"
                options={connectionStates}
                selectedOptions={selectedConnectionStates}
                onChange={setSelectedConnectionStates}
              />
            </div>
          </div>

          {/* Results count */}
          {debouncedSearch && (
            <div className="mb-4 text-sm text-gray-500">
              Found {filteredProjectsList.length} matching projects
            </div>
          )}

          {/* Notion Projects Table with inline loading */}
          <div className="bg-white shadow-md rounded-lg overflow-hidden">
            {loading ? (
              <div className="flex justify-center items-center h-64">
                <Loader />
              </div>
            ) : (
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Project Name
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Client
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Status
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Appetite
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Notion
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {filteredProjectsList.map((project) => (
                    <tr key={project.id} className="hover:bg-gray-50">
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm font-medium text-gray-900">
                          {project.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {project.client?.name || 'No Client'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          project.status === 'In Progress' ? 'bg-green-100 text-green-800' :
                          project.status === 'Blocked' ? 'bg-red-100 text-red-800' :
                          'bg-gray-100 text-gray-800'
                        }`}>
                          {project.status}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-500">
                          {project.appetite || 'N/A'}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {project.isConnected ? (
                          <button
                            onClick={() => handleSync(project)}
                            className="px-3 py-1 bg-green-600 text-white text-sm font-medium rounded-md hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                            disabled={loadingStates[project.id]}
                          >
                            {loadingStates[project.id] ? 'Syncing...' : 'Sync'}
                          </button>
                        ) : (
                          <button
                            onClick={() => handleConnect(project)}
                            className="px-3 py-1 bg-blue-600 text-white text-sm font-medium rounded-md hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                            disabled={loadingStates[project.id]}
                          >
                            {loadingStates[project.id] ? 'Importing...' : 'Import'}
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      ) : (
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Project Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Client
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Toggl
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {supabaseProjects.map((project) => (
                <tr key={project.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {project.title}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-500">
                      {project.client_name || 'No Client'}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      project.status === 'In Progress' ? 'bg-green-100 text-green-800' :
                      project.status === 'Blocked' ? 'bg-red-100 text-red-800' :
                      'bg-gray-100 text-gray-800'
                    }`}>
                      {project.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <TogglProjectSelect 
                      projectId={project.id}
                      currentTogglId={project.toggl_id}
                      onSelect={(togglId) => {
                        console.log('Parent onSelect called:', {
                          togglId,
                          projectId: project.id,
                          currentState: project
                        });
                        setSupabaseProjects(currentProjects =>
                          currentProjects.map(p => {
                            if (p.id === project.id) {
                              const updated = { ...p, toggl_id: togglId };
                              console.log('Updating project state:', {
                                before: p,
                                after: updated
                              });
                              return updated;
                            }
                            return p;
                          })
                        );
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ProjectConnections; 