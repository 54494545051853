import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../ui/dialog"
import { Button } from "../ui/button"

const TransactionModal = ({
  isOpen,
  onClose,
  title,
  transaction,
  setTransaction,
  handleSave,
  isSaving,
  type,
  ProjectSelector,
}) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Enter' && isOpen && !isSaving) {
        e.preventDefault();
        handleSave();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, isSaving, handleSave]);

  if (!transaction) return null;

  const isDuplicating = transaction.amount && !transaction.id;
  const modalTitle = isDuplicating 
    ? `Duplicate ${type === 'income' ? 'Income' : 'Expense'}`
    : title;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] shadow-lg" overlayClassName="bg-white/50">
        <DialogHeader>
          <DialogTitle>{modalTitle}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Project</label>
            <ProjectSelector
              value={transaction?.project_id || ''}
              onChange={(value) => setTransaction(prev => ({ ...prev, project_id: value }))}
              disabled={isSaving}
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Amount</label>
            <input
              type="number"
              value={transaction?.amount || ''}
              onChange={(e) => setTransaction(prev => ({ ...prev, amount: e.target.value }))}
              className="w-full p-2 border rounded"
              placeholder="Amount"
              disabled={isSaving}
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Date</label>
            <input
              type="date"
              value={transaction?.date || ''}
              onChange={(e) => {
                const localDate = new Date(e.target.value + 'T00:00:00');
                const dateString = localDate.toISOString().split('T')[0];
                setTransaction(prev => ({ ...prev, date: dateString }));
              }}
              className="w-full p-2 border rounded"
              disabled={isSaving}
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Payment Method</label>
            <select
              value={transaction?.payment_method || 'Invoice'}
              onChange={(e) => setTransaction(prev => ({ ...prev, payment_method: e.target.value }))}
              className="w-full p-2 border rounded"
              disabled={isSaving}
            >
              <option value="Invoice">Invoice</option>
              <option value="Cash">Cash</option>
              <option value="Bank Transfer">Bank Transfer</option>
            </select>
          </div>
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button 
            onClick={handleSave} 
            disabled={isSaving}
            className="bg-green-600 hover:bg-green-700 text-white"
          >
            {isSaving ? 'Saving...' : 'Save'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TransactionModal; 