import React, { useState, useEffect } from 'react';
import { DollarSign, ChevronDown, ChevronUp } from 'lucide-react';
import PercentageSlider from '../components/PercentageSlider';

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

function Pricing() {
  console.log("Rendering Pricing component");
  const [hourlyRate, setHourlyRate] = useState(100);
  const [weeklyHours, setWeeklyHours] = useState(30);
  const [percentages, setPercentages] = useState([35, 15, 50]);
  const [weeklyPrices, setWeeklyPrices] = useState([]);
  const [showAmendments, setShowAmendments] = useState(false);
  const [enabledWeeks, setEnabledWeeks] = useState([true, true, true, true]);
  const [showTeamBreakdown, setShowTeamBreakdown] = useState([false, false, false, false]);

  useEffect(() => {
    const calculateBreakdown = (weeks) => {
      const total = hourlyRate * weeklyHours * weeks;
      const teamCost = total * (percentages[0] / 100);
      const overhead = total * (percentages[1] / 100);
      const profit = total * (percentages[2] / 100);
      return {
        total,
        teamCost,
        overhead,
        profit,
        developerCost: teamCost * 0.7,
        productOwnerCost: teamCost * 0.2,
        designerCost: teamCost * 0.1
      };
    };

    setWeeklyPrices([
      calculateBreakdown(1),
      calculateBreakdown(2),
      calculateBreakdown(4),
      calculateBreakdown(6)
    ]);
  }, [hourlyRate, weeklyHours, percentages]);

  const handleHourlyRateChange = (e) => {
    const value = parseFloat(e.target.value);
    setHourlyRate(isNaN(value) ? 0 : value);
  };

  const handleWeeklyHoursChange = (e) => {
    const value = parseFloat(e.target.value);
    setWeeklyHours(isNaN(value) ? 0 : value);
  };

  const toggleWeek = (index) => {
    setEnabledWeeks(prev => prev.map((enabled, i) => i === index ? !enabled : enabled));
  };

  const toggleTeamBreakdown = (index) => {
    setShowTeamBreakdown(prev => prev.map((show, i) => i === index ? !show : show));
  };

  return (
    <div className="bg-[#f9fbfb] min-h-screen p-6">
      <div className="container mx-auto">
        <h1 className="text-3xl font-bold mb-6">Pricing Calculator</h1>
        
        {/* Pricing Variables Container */}
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4">Pricing Variables</h2>
          <div className="flex space-x-4 mb-4">
            <div>
              <label htmlFor="hourlyRate" className="block text-sm font-medium text-gray-700 mb-1">
                Hourly Rate
              </label>
              <div className="flex items-center">
                <DollarSign className="w-5 h-5 text-gray-400 mr-2" />
                <input
                  id="hourlyRate"
                  type="number"
                  value={hourlyRate}
                  onChange={handleHourlyRateChange}
                  className="border rounded-md p-2 w-24"
                />
              </div>
            </div>
            <div>
              <label htmlFor="weeklyHours" className="block text-sm font-medium text-gray-700 mb-1">
                Weekly Hours
              </label>
              <input
                id="weeklyHours"
                type="number"
                value={weeklyHours}
                onChange={handleWeeklyHoursChange}
                className="border rounded-md p-2 w-24"
              />
            </div>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Cost Breakdown
            </label>
            <PercentageSlider percentages={percentages} setPercentages={setPercentages} />
            <div className="flex justify-between mt-2 text-sm text-gray-600">
              <span>Team: {percentages[0]}%</span>
              <span>Overhead: {percentages[1]}%</span>
              <span>Profit: {percentages[2]}%</span>
            </div>
          </div>
        </div>

        {/* Week Options Container */}
        <div className="bg-gray-100 rounded-lg p-6">
          <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            {['1 Week', '2 Weeks', '4 Weeks', '6 Weeks'].map((duration, index) => (
              <div key={duration} className={`bg-white shadow rounded-lg p-4 ${enabledWeeks[index] ? 'opacity-100' : 'opacity-50'}`}>
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-semibold">{duration}</h3>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      className="sr-only peer"
                      checked={enabledWeeks[index]}
                      onChange={() => toggleWeek(index)}
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                  </label>
                </div>
                <div className="mb-4">
                  <span className="text-2xl font-bold text-blue-600">{formatCurrency(weeklyPrices[index]?.total || 0)}</span>
                </div>
                <div className="grid grid-cols-3 gap-4 text-center mb-4">
                  <div>
                    <div className="text-xl font-bold">{formatCurrency(weeklyPrices[index]?.teamCost || 0)}</div>
                    <div className="text-sm text-gray-500">Team ({percentages[0]}%)</div>
                    <button
                      onClick={() => toggleTeamBreakdown(index)}
                      className="text-xs text-blue-600 hover:underline mt-1"
                    >
                      {showTeamBreakdown[index] ? 'Hide breakdown' : 'See breakdown'}
                    </button>
                  </div>
                  <div>
                    <div className="text-xl font-bold">{formatCurrency(weeklyPrices[index]?.overhead || 0)}</div>
                    <div className="text-sm text-gray-500">Overhead ({percentages[1]}%)</div>
                  </div>
                  <div>
                    <div className="text-xl font-bold">{formatCurrency(weeklyPrices[index]?.profit || 0)}</div>
                    <div className="text-sm text-gray-500">Profit ({percentages[2]}%)</div>
                  </div>
                </div>
                {showTeamBreakdown[index] && (
                  <div className="border-t pt-4">
                    <h4 className="text-sm font-semibold mb-2">Team Budget Breakdown</h4>
                    <div className="grid grid-cols-3 gap-4 text-center">
                      <div>
                        <div className="text-lg font-bold">{formatCurrency(weeklyPrices[index]?.developerCost || 0)}</div>
                        <div className="text-xs text-gray-500">Developer (70%)</div>
                      </div>
                      <div>
                        <div className="text-lg font-bold">{formatCurrency(weeklyPrices[index]?.productOwnerCost || 0)}</div>
                        <div className="text-xs text-gray-500">Product Owner (20%)</div>
                      </div>
                      <div>
                        <div className="text-lg font-bold">{formatCurrency(weeklyPrices[index]?.designerCost || 0)}</div>
                        <div className="text-xs text-gray-500">Designer (10%)</div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Amendments History */}
        <div className="bg-white shadow rounded-lg p-4 mt-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">Amendments History</h3>
            <button
              className="text-blue-600 hover:underline"
              onClick={() => setShowAmendments(!showAmendments)}
            >
              {showAmendments ? <ChevronUp className="h-4 w-4" /> : <ChevronDown className="h-4 w-4" />}
            </button>
          </div>
          {showAmendments && (
            <div className="space-y-4">
              <div className="grid grid-cols-4 gap-4 text-sm font-medium text-gray-500">
                <div>Date</div>
                <div>Reason</div>
                <div className="text-right">Est. Total</div>
                <div></div>
              </div>
              <div className="grid grid-cols-4 gap-4 text-sm">
                <div>19 Sep 2024</div>
                <div className="flex items-center">
                  <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">T</span>
                  Rate adjustment
                </div>
                <div className="text-right">{formatCurrency(weeklyPrices[0]?.total || 0)}</div>
                <div className="text-right text-green-500">▲</div>
              </div>
              {/* Add more amendment rows as needed */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Pricing;
