import { useState, useMemo } from 'react'
import { Users, Briefcase, X } from 'lucide-react'

const pods = [
  {
    id: 1,
    name: "Alpha Pod",
    productManager: { name: "Alice Johnson", avatar: "https://i.pravatar.cc/40?img=1" },
    developer: { name: "Bob Smith", avatar: "https://i.pravatar.cc/40?img=2" },
    designer: { name: "Carol Williams", avatar: "https://i.pravatar.cc/40?img=3" },
    projects: [
      { id: 1, name: "Project X", client: "Acme Corp", startDate: "2024-08-01", endDate: "2024-09-30", appetite: 8, status: "IN PROGRESS" },
      { id: 2, name: "Project Y", client: "TechStart Inc.", startDate: "2024-09-01", endDate: "2024-09-14", appetite: 2, status: "COMPLETED" },
      { id: 3, name: "Project Z", client: "InnovateCo", startDate: "2024-09-15", endDate: "2024-10-13", appetite: 4, status: "DEV READY" },
    ],
  },
  {
    id: 2,
    name: "Beta Pod",
    productManager: { name: "David Brown", avatar: "https://i.pravatar.cc/40?img=4" },
    developer: { name: "Eva Davis", avatar: "https://i.pravatar.cc/40?img=5" },
    designer: { name: "Frank Miller", avatar: "https://i.pravatar.cc/40?img=6" },
    projects: [
      { id: 4, name: "Project A", client: "Global Systems", startDate: "2024-08-15", endDate: "2024-09-26", appetite: 6, status: "IN REVIEW" },
      { id: 5, name: "Project B", client: "Local Solutions", startDate: "2024-09-10", endDate: "2024-10-08", appetite: 4, status: "IN PROGRESS" },
    ],
  },
  {
    id: 3,
    name: "Gamma Pod",
    productManager: { name: "Grace Lee", avatar: "https://i.pravatar.cc/40?img=7" },
    developer: { name: "Henry Wilson", avatar: "https://i.pravatar.cc/40?img=8" },
    designer: { name: "Isla Garcia", avatar: "https://i.pravatar.cc/40?img=9" },
    projects: [
      { id: 6, name: "Project C", client: "Tech Innovators", startDate: "2024-09-01", endDate: "2024-10-13", appetite: 6, status: "IN PROGRESS" },
      { id: 7, name: "Project D", client: "Creative Solutions", startDate: "2024-09-15", endDate: "2024-09-29", appetite: 2, status: "SHAPING" },
    ],
  },
  {
    id: 4,
    name: "Delta Pod",
    productManager: { name: "Jack Thompson", avatar: "https://i.pravatar.cc/40?img=10" },
    developer: { name: "Karen Chen", avatar: "https://i.pravatar.cc/40?img=11" },
    designer: { name: "Liam O'Connor", avatar: "https://i.pravatar.cc/40?img=12" },
    projects: [
      { id: 8, name: "Project E", client: "Digital Dynamics", startDate: "2024-08-20", endDate: "2024-09-17", appetite: 4, status: "BLOCKED" },
      { id: 9, name: "Project F", client: "Future Tech", startDate: "2024-09-18", endDate: "2024-10-16", appetite: 4, status: "DEV READY" },
      { id: 10, name: "Project G", client: "Smart Systems", startDate: "2024-09-01", endDate: "2024-09-28", appetite: 4, status: "IN REVIEW" },
    ],
  },
  {
    id: 5,
    name: "Epsilon Pod",
    productManager: { name: "Mia Rodriguez", avatar: "https://i.pravatar.cc/40?img=13" },
    developer: { name: "Nathan Kim", avatar: "https://i.pravatar.cc/40?img=14" },
    designer: { name: "Olivia Patel", avatar: "https://i.pravatar.cc/40?img=15" },
    projects: [
      { id: 11, name: "Project H", client: "Eco Solutions", startDate: "2024-09-05", endDate: "2024-10-03", appetite: 4, status: "IN PROGRESS" },
      { id: 12, name: "Project I", client: "Health Innovations", startDate: "2024-09-15", endDate: "2024-10-13", appetite: 4, status: "SHAPING" },
    ],
  },
  {
    id: 6,
    name: "Zeta Pod",
    productManager: { name: "Paul Martinez", avatar: "https://i.pravatar.cc/40?img=16" },
    developer: { name: "Quinn Foster", avatar: "https://i.pravatar.cc/40?img=17" },
    designer: { name: "Rachel Tanaka", avatar: "https://i.pravatar.cc/40?img=18" },
    projects: [
      { id: 13, name: "Project J", client: "AI Ventures", startDate: "2024-08-25", endDate: "2024-09-22", appetite: 4, status: "COMPLETED" },
      { id: 14, name: "Project K", client: "Quantum Computing", startDate: "2024-09-23", endDate: "2024-10-21", appetite: 4, status: "DEV READY" },
      { id: 15, name: "Project L", client: "Blockchain Solutions", startDate: "2024-09-10", endDate: "2024-10-08", appetite: 4, status: "IN PROGRESS" },
    ],
  },
];

function PodListItem({ pod, isSelected, onClick }) {
  return (
    <div 
      className={`p-4 border-b cursor-pointer ${isSelected ? 'bg-blue-100' : 'hover:bg-gray-100'}`}
      onClick={() => onClick(pod)}
    >
      <h2 className="text-lg font-semibold">{pod.name}</h2>
      <p className="text-sm text-gray-600">{pod.projects.length} Projects</p>
    </div>
  )
}

function PodForm({ pod, onClose, onSave }) {
  const [name, setName] = useState(pod.name)
  const [members, setMembers] = useState([pod.productManager, pod.developer, pod.designer])
  const [newMember, setNewMember] = useState({ name: '', role: '' })

  const addMember = () => {
    if (newMember.name && newMember.role) {
      setMembers([...members, { ...newMember, avatar: `https://i.pravatar.cc/40?img=${members.length + 1}` }])
      setNewMember({ name: '', role: '' })
    }
  }

  const removeMember = (index) => {
    setMembers(members.filter((_, i) => i !== index))
  }

  const handleSave = () => {
    onSave({ ...pod, name, productManager: members[0], developer: members[1], designer: members[2] })
    onClose()
  }

  const activeProjects = useMemo(() => 
    pod.projects.filter(p => ['DEV READY', 'IN PROGRESS', 'IN REVIEW'].includes(p.status)),
    [pod.projects]
  )

  return (
    <form className="bg-white shadow-md rounded-lg overflow-hidden">
      <div className="space-y-6 p-6">
        <div className="border-b border-gray-900/10 pb-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-3xl font-bold leading-9 text-gray-900">Edit Pod</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>
          <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label htmlFor="pod-name" className="block text-sm font-medium leading-6 text-gray-900">
                Pod Name
              </label>
              <div className="mt-2">
                <input
                  id="pod-name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Team Members</h3>
          <div className="mt-2 space-y-4">
            {members.map((member, index) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={member.avatar} alt={member.name} className="w-8 h-8 rounded-full mr-2" />
                  <span className="text-sm text-gray-900">{member.name} - {member.role}</span>
                </div>
                <button onClick={() => removeMember(index)} className="text-red-500 hover:text-red-700">
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="border-b border-gray-900/10 pb-6">
          <h3 className="text-base font-semibold leading-7 text-gray-900">Add New Member</h3>
          <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <input
                type="text"
                placeholder="Name"
                value={newMember.name}
                onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <div className="sm:col-span-3">
              <input
                type="text"
                placeholder="Role"
                value={newMember.role}
                onChange={(e) => setNewMember({ ...newMember, role: e.target.value })}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt-2">
            <button
              type="button"
              onClick={addMember}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add Member
            </button>
          </div>
        </div>

        <div>
          <h3 className="text-base font-semibold leading-7 text-gray-900">Active Projects</h3>
          <ul className="mt-2 list-disc list-inside text-sm text-gray-600">
            {activeProjects.map(project => (
              <li key={project.id}>{project.name} - {project.status}</li>
            ))}
          </ul>
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
        <button
          type="button"
          onClick={onClose}
          className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mr-3"
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={handleSave}
          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save Changes
        </button>
      </div>
    </form>
  )
}

export default function Pods() {
  const [selectedPod, setSelectedPod] = useState(null)
  const [podsData, setPodsData] = useState(pods)

  const handlePodSelect = (pod) => {
    setSelectedPod(pod)
  }

  const handlePodSave = (updatedPod) => {
    setPodsData(podsData.map(pod => pod.id === updatedPod.id ? updatedPod : pod))
    setSelectedPod(null)
  }

  return (
    <div className="bg-[#f9fbfb] min-h-screen p-6 flex">
      <div className="w-1/3 bg-white rounded-lg shadow-md overflow-hidden mr-6">
        <h1 className="text-2xl font-bold p-4 bg-gray-100">All Pods</h1>
        <div className="overflow-y-auto" style={{ maxHeight: 'calc(100vh - 10rem)' }}>
          {podsData.map((pod) => (
            <PodListItem
              key={pod.id}
              pod={pod}
              isSelected={selectedPod && selectedPod.id === pod.id}
              onClick={handlePodSelect}
            />
          ))}
        </div>
      </div>
      <div className="w-2/3">
        {selectedPod ? (
          <PodForm
            pod={selectedPod}
            onClose={() => setSelectedPod(null)}
            onSave={handlePodSave}
          />
        ) : (
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-2xl font-bold mb-4">Select a Pod</h2>
            <p className="text-gray-600">Click on a pod from the list to view and edit its details.</p>
          </div>
        )}
      </div>
    </div>
  )
}
