import React, { useState } from 'react';
import { signInWithGoogle } from '../utils/supabaseClient';

const Login = () => {
  const [error, setError] = useState(null);

  const handleGoogleLogin = async () => {
    try {
      await signInWithGoogle();
    } catch (error) {
      setError(error.error_description || error.message);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/Pattern7.png')" }}>
      <div className="w-full max-w-md p-8 space-y-8 bg-white bg-opacity-90 rounded-lg shadow-2xl">
        <div className="text-center">
          <img src="/inovo_logo.svg" alt="Inovo Logo" className="mx-auto w-56 mb-6" />
          <h2 className="mt-2 text-3xl font-extrabold text-gray-900">Welcome  👋</h2>
          <p className="mt-2 text-sm text-gray-600">
            Access Inovo projects, tasks, and team with our custom dashboard.
          </p>
        </div>
        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <span className="block sm:inline">{error}</span>
          </div>
        )}
        <div className="mt-8 space-y-6">
          <button
            onClick={handleGoogleLogin}
            className="group relative w-full flex items-center justify-center py-3 px-4 border border-transparent text-base font-bold rounded-md text-white bg-[#FF524B] hover:bg-[#E64A43] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FF524B] shadow-md transition-all duration-200 ease-in-out h-14"
          >
            <span className="flex items-center">
              <svg className="h-6 w-6 text-white mr-3" viewBox="0 0 24 24" fill="currentColor">
                <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" />
                <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" />
                <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" />
                <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" />
                <path d="M1 1h22v22H1z" fill="none" />
              </svg>
              Sign in with Google
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
