import React from 'react';
import { supabase } from '../utils/supabaseClient';
import { useNavigate } from 'react-router-dom';

const PendingApproval = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-cover bg-center" style={{ backgroundImage: "url('/Pattern7.png')" }}>
      <div className="w-full max-w-md p-8 space-y-8 bg-white bg-opacity-90 rounded-lg shadow-2xl">
        <div className="text-center">
          <img src="/inovo_logo.svg" alt="Inovo Logo" className="mx-auto w-56 mb-6" />
          <h2 className="mt-2 text-3xl font-extrabold text-gray-900">Account Pending Approval</h2>
          <p className="mt-2 text-sm text-gray-600">
            Your account is currently pending approval. Please reach out to admin@inovo.io if you have any questions.
          </p>
        </div>
        <div className="mt-8 space-y-6">
          <button
            onClick={handleLogout}
            className="group relative w-full flex items-center justify-center py-3 px-4 border border-transparent text-base font-bold rounded-md text-white bg-inovo-primary hover:bg-inovo-primary-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-inovo-primary shadow-md transition-all duration-200 ease-in-out h-14"
          >
            Sign Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default PendingApproval;