import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CalendarIcon, PlusIcon, XMarkIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { PencilIcon, TrashIcon, DocumentDuplicateIcon } from '@heroicons/react/24/solid';
import { supabase } from '../utils/supabaseClient';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../components/ui/dropdown-menu"
import { Button } from "../components/ui/button"
import TransactionTable from '../components/cashflow/TransactionTable';
import { ActionDropdown } from '../components/cashflow/ActionDropdown';
import StatsBoxes from '../components/cashflow/StatsBoxes';
import MonthSelector from '../components/cashflow/MonthSelector';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select"
import TransactionModal from '../components/cashflow/TransactionModal';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error caught by ErrorBoundary:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Something went wrong. Please try again later.</div>;
    }

    return this.props.children;
  }
}

const CashFlowErrorBoundary = ({ children }) => {
  return (
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  );
};

const CashFlow = () => {
  // Get today's date in YYYY-MM-DD format in local timezone
  const today = new Date().toLocaleDateString('en-CA'); // This returns YYYY-MM-DD in local timezone
  
  const [cashflow, setCashflow] = useState([]);
  const [incomeData, setIncomeData] = useState([]);
  const [expenseData, setExpenseData] = useState([]);
  const [newIncome, setNewIncome] = useState({ 
    project_id: '', 
    amount: '', 
    date: today,
    payment_type: 'credit', 
    payment_method: 'Invoice' 
  });
  const [newExpense, setNewExpense] = useState({ 
    project_id: '', 
    amount: '', 
    date: today,
    payment_type: 'debit', 
    payment_method: 'Invoice' 
  });
  const [editingIncome, setEditingIncome] = useState(null);
  const [editingExpense, setEditingExpense] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const dropdownRef = useRef(null);
  const [isAddingIncome, setIsAddingIncome] = useState(false);
  const [newlyAddedId, setNewlyAddedId] = useState(null);
  const [isAddingExpense, setIsAddingExpense] = useState(false);
  const [newlyAddedExpenseId, setNewlyAddedExpenseId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isSaving, setIsSaving] = useState(false);
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionType, setTransactionType] = useState(null);

  useEffect(() => {
    fetchPayments();
    fetchProjects();
  }, []);

  useEffect(() => {
    const handleKeyboardShortcuts = (e) => {
      // Check if Command (Mac) or Control (Windows) is pressed
      const isCmdOrCtrl = e.metaKey || e.ctrlKey;
      
      if (isCmdOrCtrl) {
        switch (e.key.toLowerCase()) {
          case 'e':
            e.preventDefault(); // Prevent default browser behavior
            setNewExpense({
              project_id: '',
              amount: '',
              date: today,
              payment_type: 'debit',
              payment_method: 'Invoice'
            });
            setIsModalOpen(true);
            setTransactionType('expense');
            break;
            
          case 'i':
            e.preventDefault(); // Prevent default browser behavior
            setNewIncome({
              project_id: '',
              amount: '',
              date: today,
              payment_type: 'credit',
              payment_method: 'Invoice'
            });
            setIsModalOpen(true);
            setTransactionType('income');
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyboardShortcuts);
    
    return () => {
      window.removeEventListener('keydown', handleKeyboardShortcuts);
    };
  }, [today]); // Add any other dependencies if needed

  const fetchPayments = async () => {
    try {
      const { data, error } = await supabase
        .from('payments')
        .select(`
          *,
          project:project_id (
            id,
            title,
            client:client_id (
              id,
              name
            )
          )
        `)
        .order('date', { ascending: false });

      if (error) throw error;

      const validData = data.filter(payment => 
        payment && 
        payment.date && 
        payment.amount !== null && 
        payment.amount !== undefined
      );

      setIncomeData(validData.filter(payment => payment.payment_type === 'credit'));
      setExpenseData(validData.filter(payment => payment.payment_type === 'debit'));

      const processedData = processDataForCashflow(validData);
      setCashflow(processedData);
    } catch (error) {
      console.error('Error fetching payments:', error.message);
    }
  };

  const fetchProjects = async () => {
    try {
      const { data, error } = await supabase
        .from('projects')
        .select(`
          id,
          title,
          client:client_id (
            id,
            name
          )
        `)
        .order('title');

      if (error) throw error;
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const processDataForCashflow = (data) => {
    const today = new Date();
    const currentYear = today.getFullYear();
    
    const monthsInYear = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(currentYear, i);
      const monthLabel = date.toLocaleString('default', { 
        month: 'short',
        year: '2-digit'
      });
      const formattedMonth = `${monthLabel.split(' ')[0]} '${monthLabel.split(' ')[1]}`;
      return formattedMonth;
    }).reduce((acc, month) => {
      acc[month] = { 
        income: 0, 
        projectedIncome: 0, 
        expenses: 0,
        projectedExpenses: 0  // Add projected expenses
      };
      return acc;
    }, {});

    // Process the actual data
    data.forEach(payment => {
      if (!payment.date) return;
      
      const paymentDate = new Date(payment.date);
      if (paymentDate.getFullYear() !== currentYear) return;
      
      const month = `${paymentDate.toLocaleString('default', { month: 'short' })} '${paymentDate.getFullYear().toString().slice(-2)}`;
      
      if (payment.payment_type === 'credit' && payment.amount) {
        if (paymentDate <= today) {
          monthsInYear[month].income += Number(payment.amount);
        } else {
          monthsInYear[month].projectedIncome += Number(payment.amount);
        }
      } else if (payment.payment_type === 'debit' && payment.amount) {
        if (paymentDate <= today) {
          monthsInYear[month].expenses += Number(payment.amount);
        } else {
          monthsInYear[month].projectedExpenses += Number(payment.amount);  // Add projected expenses
        }
      }
    });

    return Object.entries(monthsInYear)
      .map(([month, values]) => ({
        month,
        income: values.income,
        projectedIncome: values.projectedIncome,
        expenses: values.expenses,
        projectedExpenses: values.projectedExpenses  // Include in return data
      }));
  };

  const handleAddIncome = async () => {
    if (!newIncome.amount || !newIncome.date) {
      alert('Please fill in all required fields (amount and date)');
      return;
    }

    setIsAddingIncome(true);

    try {
      const paymentData = {
        project_id: newIncome.project_id || null,
        amount: Number(newIncome.amount),
        date: newIncome.date,
        payment_type: 'credit',
        payment_method: newIncome.payment_method,
        notion_id: 'cashflow'
      };

      const { data, error } = await supabase
        .from('payments')
        .insert([paymentData])
        .select();

      if (error) throw error;

      // Set the newly added ID
      setNewlyAddedId(data[0].id);
      
      await fetchPayments();
      setNewIncome({ 
        project_id: '', 
        amount: '', 
        date: today,  // Reset to today instead of empty string
        payment_type: 'credit', 
        payment_method: 'Invoice' 
      });

      // Clear the newly added ID after 3 seconds
      setTimeout(() => {
        setNewlyAddedId(null);
      }, 3000);

    } catch (error) {
      console.error('Error adding income:', error);
      alert(`Failed to add income: ${error.message}`);
    } finally {
      setIsAddingIncome(false);
    }
  };

  const handleAddExpense = async () => {
    if (!newExpense.amount || !newExpense.date) {
      alert('Please fill in all required fields (amount and date)');
      return;
    }

    setIsAddingExpense(true);

    try {
      const paymentData = {
        project_id: newExpense.project_id || null,
        amount: Number(newExpense.amount),
        date: newExpense.date,
        payment_type: 'debit',
        payment_method: newExpense.payment_method,
        notion_id: 'cashflow'
      };

      const { data, error } = await supabase
        .from('payments')
        .insert([paymentData])
        .select();

      if (error) throw error;

      setNewlyAddedExpenseId(data[0].id);
      
      await fetchPayments();
      setNewExpense({ 
        project_id: '', 
        amount: '', 
        date: today,  // Reset to today instead of empty string
        payment_type: 'debit', 
        payment_method: 'Invoice' 
      });

      setTimeout(() => {
        setNewlyAddedExpenseId(null);
      }, 3000);

    } catch (error) {
      console.error('Error adding expense:', error);
      alert(`Failed to add expense: ${error.message}`);
    } finally {
      setIsAddingExpense(false);
    }
  };

  const handleEditStart = (id, type) => {
    setEditingId(id);
    if (type === 'income') {
      setEditingIncome(incomeData.find(income => income.id === id));
    } else {
      setEditingExpense(expenseData.find(expense => expense.id === id));
    }
  };

  const handleEditCancel = () => {
    setEditingId(null);
    setEditingIncome(null);
    setEditingExpense(null);
  };

  const handleUpdateIncome = async () => {
    setIsSaving(true);
    try {
      const { error } = await supabase
        .from('payments')
        .update({
          ...editingIncome,
          amount: Number(editingIncome.amount)
        })
        .eq('id', editingIncome.id);

      if (error) throw error;

      await fetchPayments();
      setEditingId(null);
      setEditingIncome(null);
    } catch (error) {
      console.error('Error updating income:', error.message);
      alert('Failed to update income. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleUpdateExpense = async () => {
    setIsSaving(true);
    try {
      const { error } = await supabase
        .from('payments')
        .update({
          ...editingExpense,
          amount: Number(editingExpense.amount)
        })
        .eq('id', editingExpense.id);

      if (error) throw error;

      await fetchPayments();
      setEditingId(null);
      setEditingExpense(null);
    } catch (error) {
      console.error('Error updating expense:', error.message);
      alert('Failed to update expense. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteIncome = async (id) => {
    try {
      const { error } = await supabase
        .from('payments')
        .delete()
        .eq('id', id);

      if (error) throw error;

      await fetchPayments();
    } catch (error) {
      console.error('Error deleting income:', error.message);
      alert('Failed to delete income. Please try again.');
    }
  };

  const handleDeleteExpense = async (id) => {
    try {
      const { error } = await supabase
        .from('payments')
        .delete()
        .eq('id', id);

      if (error) throw error;

      await fetchPayments();
    } catch (error) {
      console.error('Error deleting expense:', error.message);
      alert('Failed to delete expense. Please try again.');
    }
  };

  const handleDuplicateTransaction = (transaction) => {
    const duplicatedTransaction = {
      ...transaction,
      date: today, // Set to today's date instead of keeping original date
      id: undefined // Remove the id so it creates a new record
    };

    if (transaction.payment_type === 'credit') {
      setNewIncome(duplicatedTransaction);
    } else {
      setNewExpense(duplicatedTransaction);
    }
    
    setTransactionType(transaction.payment_type === 'credit' ? 'income' : 'expense');
    setIsModalOpen(true);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const income = payload[0]?.value || 0;
      const projectedIncome = payload[1]?.value || 0;
      const expenses = payload[2]?.value || 0;
      const projectedExpenses = payload[3]?.value || 0;
      const totalIncome = income + projectedIncome;
      const totalExpenses = expenses + projectedExpenses;
      const balance = totalIncome - totalExpenses;

      return (
        <div className="bg-white p-4 border rounded shadow-lg">
          <p className="font-bold">{label}</p>
          <p className="text-green-600">Actual Income: ${income.toLocaleString()}</p>
          <p className="text-green-600 opacity-60">Projected Income: ${projectedIncome.toLocaleString()}</p>
          <p className="text-green-800 font-semibold">Total Income: ${totalIncome.toLocaleString()}</p>
          <p className="text-red-500">Actual Expenses: ${expenses.toLocaleString()}</p>
          <p className="text-red-500 opacity-60">Projected Expenses: ${projectedExpenses.toLocaleString()}</p>
          <p className="text-red-800 font-semibold">Total Expenses: ${totalExpenses.toLocaleString()}</p>
          <p className="text-blue-600 font-semibold">Balance: ${balance.toLocaleString()}</p>
        </div>
      );
    }
    return null;
  };

  const getRowClassName = (transactionId) => {
    if (transactionId === newlyAddedId) {
      return "border-t border-gray-200 bg-green-50 transition-colors duration-1000";
    }
    return "border-t border-gray-200";
  };

  const AddIncomeButton = () => (
    <button 
      onClick={handleAddIncome} 
      disabled={isAddingIncome}
      className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded text-sm flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isAddingIncome ? (
        <>
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Adding...
        </>
      ) : (
        <>
          <PlusIcon className="h-4 w-4 mr-1" />
          Add
        </>
      )}
    </button>
  );

  const getExpenseRowClassName = (transactionId) => {
    if (transactionId === newlyAddedExpenseId) {
      return "border-t border-gray-200 bg-red-50 transition-colors duration-1000";
    }
    return "border-t border-gray-200";
  };

  const AddExpenseButton = () => (
    <button 
      onClick={handleAddExpense} 
      disabled={isAddingExpense}
      className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded text-sm flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
    >
      {isAddingExpense ? (
        <>
          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Adding...
        </>
      ) : (
        <>
          <PlusIcon className="h-4 w-4 mr-1" />
          Add
        </>
      )}
    </button>
  );

  const filterTransactionsByMonth = (transactions) => {
    return transactions.filter(transaction => {
      const transactionDate = new Date(transaction.date);
      return transactionDate.getMonth() === selectedDate.getMonth() &&
             transactionDate.getFullYear() === selectedDate.getFullYear();
    });
  };

  const ProjectSelector = ({ value, onChange, disabled }) => {
    return (
      <Select
        value={value || undefined}
        onValueChange={(newValue) => {
          onChange(newValue);
        }}
        disabled={disabled}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Project" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="none">No project</SelectItem>
          {projects.map((project) => (
            <SelectItem key={project.id} value={project.id}>
              {project.title} - {project.client?.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  };

  const renderProjectInfo = (payment) => {
    if (!payment.project) return 'N/A';
    
    return (
      <div className="max-w-[200px]">
        <div className="font-medium truncate">{payment.project.title}</div>
        <div className="text-sm text-gray-500 truncate">{payment.project.client?.name}</div>
      </div>
    );
  };

  const getColumns = (type) => [
    {
      header: "Project & Client",
      cell: (payment) => {
        const date = new Date(payment.date);
        const isFuture = date > new Date();
        return (
          <div className={`max-w-[200px] ${isFuture ? 'opacity-50' : ''}`}>
            <div className="font-medium truncate">{payment.project?.title || 'N/A'}</div>
            <div className="text-sm text-gray-500 truncate">{payment.project?.client?.name}</div>
          </div>
        );
      },
      inputComponent: type === 'income' ? (
        <ProjectSelector
          value={newIncome.project_id}
          onChange={(value) => setNewIncome(prev => ({ ...prev, project_id: value }))}
          disabled={isAddingIncome}
        />
      ) : (
        <ProjectSelector
          value={newExpense.project_id}
          onChange={(value) => setNewExpense(prev => ({ ...prev, project_id: value }))}
          disabled={isAddingExpense}
        />
      )
    },
    {
      header: "Amount",
      cell: (payment) => {
        const date = new Date(payment.date);
        const isFuture = date > new Date();
        return (
          <span className={isFuture ? 'opacity-50' : ''}>
            ${Number(payment.amount).toLocaleString()}
          </span>
        );
      },
      inputComponent: type === 'income' ? (
        <input
          type="number"
          value={newIncome.amount}
          onChange={(e) => setNewIncome(prev => ({ ...prev, amount: e.target.value }))}
          className="w-full p-2 border rounded"
          placeholder="Amount"
          disabled={isAddingIncome}
        />
      ) : (
        <input
          type="number"
          value={newExpense.amount}
          onChange={(e) => setNewExpense(prev => ({ ...prev, amount: e.target.value }))}
          className="w-full p-2 border rounded"
          placeholder="Amount"
          disabled={isAddingExpense}
        />
      )
    },
    {
      header: "Date",
      cell: (payment) => {
        const date = new Date(payment.date + 'T00:00:00');
        const isFuture = date > new Date();
        return (
          <span className={isFuture ? 'opacity-50' : ''}>
            {`${date.getMonth() + 1}/${date.getDate()}`}
          </span>
        );
      },
      inputComponent: type === 'income' ? (
        <input
          type="date"
          value={newIncome.date}
          onChange={(e) => {
            const localDate = new Date(e.target.value + 'T00:00:00');
            const dateString = localDate.toISOString().split('T')[0];
            setNewIncome(prev => ({ ...prev, date: dateString }));
          }}
          className="w-full p-2 border rounded"
          disabled={isAddingIncome}
        />
      ) : (
        <input
          type="date"
          value={newExpense.date}
          onChange={(e) => {
            const localDate = new Date(e.target.value + 'T00:00:00');
            const dateString = localDate.toISOString().split('T')[0];
            setNewExpense(prev => ({ ...prev, date: dateString }));
          }}
          className="w-full p-2 border rounded"
          disabled={isAddingExpense}
        />
      )
    },
    {
      header: "Payment Method",
      cell: (payment) => {
        const date = new Date(payment.date);
        const isFuture = date > new Date();
        return (
          <span className={isFuture ? 'opacity-50' : ''}>
            {payment.payment_method}
          </span>
        );
      },
      inputComponent: type === 'income' ? (
        <select
          value={newIncome.payment_method}
          onChange={(e) => setNewIncome(prev => ({ ...prev, payment_method: e.target.value }))}
          className="w-full p-2 border rounded"
          disabled={isAddingIncome}
        >
          <option value="Invoice">Invoice</option>
          <option value="Cash">Cash</option>
          <option value="Bank Transfer">Bank Transfer</option>
        </select>
      ) : (
        <select
          value={newExpense.payment_method}
          onChange={(e) => setNewExpense(prev => ({ ...prev, payment_method: e.target.value }))}
          className="w-full p-2 border rounded"
          disabled={isAddingExpense}
        >
          <option value="Invoice">Invoice</option>
          <option value="Cash">Cash</option>
          <option value="Bank Transfer">Bank Transfer</option>
        </select>
      )
    }
  ];

  const handleModalClose = () => {
    setIsModalOpen(false);
    setTransactionType(null);
  };

  const handleModalSave = async () => {
    if (transactionType === 'income') {
      await handleAddIncome();
    } else {
      await handleAddExpense();
    }
    handleModalClose();
  };

  return (
    <CashFlowErrorBoundary>
      <div className="min-h-screen bg-[#F8F9FC]">
        <div className="container mx-auto px-6 py-4 space-y-6">
          <div className="flex justify-between items-center mb-6">
            <h1 className="text-3xl font-bold">Cashflow Dashboard</h1>
            <MonthSelector 
              selectedDate={selectedDate}
              onDateChange={setSelectedDate}
            />
          </div>
          
          <StatsBoxes 
            transactions={[...incomeData, ...expenseData]} 
            selectedDate={selectedDate}
          />

          <div className="bg-white p-6 rounded-2xl shadow-sm">
            <h2 className="text-xl font-bold mb-2">Cashflow Overview</h2>
            <div className="h-[400px]">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={cashflow} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis 
                    axisLine={false} 
                    tickFormatter={() => ''} 
                    tick={false} 
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar dataKey="income" stackId="a" fill="#10B981" name="Actual Income" />
                  <Bar dataKey="projectedIncome" stackId="a" fill="rgba(16, 185, 129, 0.2)" name="Projected Income" />
                  <Bar dataKey="expenses" stackId="b" fill="#EF4444" name="Actual Expenses" />
                  <Bar dataKey="projectedExpenses" stackId="b" fill="rgba(239, 68, 68, 0.2)" name="Projected Expenses" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className="grid gap-6 md:grid-cols-2">
            <TransactionTable 
              title="Income Transactions"
              description="Past and future income"
              transactions={filterTransactionsByMonth(incomeData)}
              newTransaction={newIncome}
              setNewTransaction={setNewIncome}
              isAdding={isAddingIncome}
              editingId={editingId}
              editingTransaction={editingIncome}
              setEditingTransaction={setEditingIncome}
              handleAdd={handleAddIncome}
              handleUpdate={handleUpdateIncome}
              handleEditStart={(id) => handleEditStart(id, 'income')}
              handleEditCancel={handleEditCancel}
              handleDelete={handleDeleteIncome}
              handleDuplicate={handleDuplicateTransaction}
              getRowClassName={getRowClassName}
              type="income"
              isSaving={isSaving}
              columns={getColumns('income')}
              className="bg-white rounded-2xl shadow-sm"
              ProjectSelector={ProjectSelector}
            />

            <TransactionTable 
              title="Expense Transactions"
              description="Past and future expenses"
              transactions={filterTransactionsByMonth(expenseData)}
              newTransaction={newExpense}
              setNewTransaction={setNewExpense}
              isAdding={isAddingExpense}
              editingId={editingId}
              editingTransaction={editingExpense}
              setEditingTransaction={setEditingExpense}
              handleAdd={handleAddExpense}
              handleUpdate={handleUpdateExpense}
              handleEditStart={(id) => handleEditStart(id, 'expense')}
              handleEditCancel={handleEditCancel}
              handleDelete={handleDeleteExpense}
              handleDuplicate={handleDuplicateTransaction}
              getRowClassName={getExpenseRowClassName}
              type="expense"
              isSaving={isSaving}
              columns={getColumns('expense')}
              className="bg-white rounded-2xl shadow-sm"
              ProjectSelector={ProjectSelector}
            />
          </div>
        </div>
        
        <TransactionModal
          isOpen={isModalOpen}
          onClose={handleModalClose}
          title={`Add ${transactionType === 'income' ? 'Income' : 'Expense'}`}
          transaction={transactionType === 'income' ? newIncome : newExpense}
          setTransaction={transactionType === 'income' ? setNewIncome : setNewExpense}
          handleSave={handleModalSave}
          isSaving={transactionType === 'income' ? isAddingIncome : isAddingExpense}
          type={transactionType}
          ProjectSelector={ProjectSelector}
        />
      </div>
    </CashFlowErrorBoundary>
  );
};

export default CashFlow;
