import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ActionDropdown } from './ActionDropdown';
import TransactionModal from './TransactionModal';
import { Button } from "../ui/button"

const TransactionTable = ({
  title,
  description,
  transactions,
  newTransaction,
  setNewTransaction,
  isAdding,
  editingTransaction,
  setEditingTransaction,
  handleAdd,
  handleUpdate,
  handleEditStart,
  handleEditCancel,
  handleDelete,
  handleDuplicate,
  getRowClassName,
  type,
  isSaving,
  columns,
  className = "",
  ProjectSelector,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleOpenAddModal = () => {
    setIsModalOpen(true);
  };

  const handleOpenEditModal = (id) => {
    handleEditStart(id);
    setIsEditModalOpen(true);
  };

  const getRowOpacity = (date) => {
    const transactionDate = new Date(date);
    const today = new Date();
    // Reset time part for accurate date comparison
    today.setHours(0, 0, 0, 0);
    transactionDate.setHours(0, 0, 0, 0);
    return transactionDate > today ? 'opacity-50' : '';
  };

  return (
    <div className={`p-6 ${className}`}>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-xl font-bold">{title}</h2>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
        <Button 
          onClick={handleOpenAddModal} 
          className={`flex items-center gap-2 rounded-xl ${
            type === 'income' 
              ? 'bg-green-50 hover:bg-green-100 text-green-600 border border-green-200' 
              : 'bg-red-50 hover:bg-red-100 text-red-600 border border-red-200'
          }`}
        >
          <PlusIcon className="h-4 w-4" />
          Add {type === 'income' ? 'Income' : 'Expense'}
        </Button>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="flex w-full">
              {columns.map((column, index) => (
                <th 
                  key={index} 
                  className={`text-left text-xs font-semibold text-gray-600 uppercase tracking-wider py-3 px-4 ${
                    index === 0 ? 'w-[200px]' :
                    index === 1 ? 'w-[120px]' :
                    index === 2 ? 'w-[120px]' :
                    'w-[120px]'
                  }`}
                >
                  {column.header}
                </th>
              ))}
              <th className="text-left text-xs font-semibold text-gray-600 uppercase tracking-wider py-3 px-4 w-[60px]">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr 
                key={transaction.id} 
                className={`flex w-full ${getRowClassName(transaction.id)}`}
              >
                {columns.map((column, index) => (
                  <td key={index} className={`py-4 px-4 ${
                    index === 0 ? 'w-[200px]' :
                    index === 1 ? 'w-[120px]' :
                    index === 2 ? 'w-[120px]' :
                    'w-[120px]'
                  }`}>
                    {column.cell(transaction)}
                  </td>
                ))}
                <td className={`py-4 px-4 w-[60px] ${getRowOpacity(transaction.date)}`}>
                  <ActionDropdown
                    transaction={transaction}
                    handleEdit={() => handleOpenEditModal(transaction.id)}
                    handleDelete={handleDelete}
                    handleDuplicate={handleDuplicate}
                    type={type}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <TransactionModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={`Add ${type === 'income' ? 'Income' : 'Expense'}`}
        transaction={newTransaction}
        setTransaction={setNewTransaction}
        handleSave={() => {
          handleAdd();
          setIsModalOpen(false);
        }}
        isSaving={isAdding}
        type={type}
        ProjectSelector={ProjectSelector}
      />

      <TransactionModal
        isOpen={isEditModalOpen}
        onClose={() => {
          handleEditCancel();
          setIsEditModalOpen(false);
        }}
        title={`Edit ${type === 'income' ? 'Income' : 'Expense'}`}
        transaction={editingTransaction}
        setTransaction={setEditingTransaction}
        handleSave={() => {
          handleUpdate();
          setIsEditModalOpen(false);
        }}
        isSaving={isSaving}
        type={type}
        ProjectSelector={ProjectSelector}
      />
    </div>
  );
};

export default TransactionTable; 