import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const signInWithGoogle = async () => {
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: `${window.location.origin}/auth/callback`
    }
  });

  if (error) {
    console.error('Error signing in with Google:', error);
    throw error;
  }

  if (data.user) {
    await linkOrCreateUser(data.user);
  }
};

const linkOrCreateUser = async (authUser) => {
  const { data: existingUser, error: fetchError } = await supabase
    .from('users')
    .select('*')
    .eq('auth_id', authUser.id)
    .single();

  if (fetchError && fetchError.code !== 'PGRST116') {
    console.error('Error fetching user:', fetchError);
    return;
  }

  if (!existingUser) {
    const { error: insertError } = await supabase
      .from('users')
      .insert({
        auth_id: authUser.id,
        email: authUser.email,
        name: authUser.user_metadata.full_name || authUser.user_metadata.name,
        role: 'pending'
      })
      .single();

    if (insertError) {
      console.error('Error creating user:', insertError);
    }
  }
};
