import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthCallback = async () => {
      const { data: sessionData, error } = await supabase.auth.getSession();
      
      if (error) {
        console.error('Error during auth callback:', error);
        navigate('/login');
        return;
      }

      if (!sessionData?.session) {
        console.log('No session found, redirecting to login');
        navigate('/login');
        return;
      }

      // Fetch user role
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('role')
        .eq('auth_id', sessionData.session.user.id)
        .single();

      if (userError) {
        console.error('Error fetching user role:', userError);
        navigate('/login');
        return;
      }

      console.log('Authentication successful, redirecting based on role');
      navigate(userData.role === 'admin' ? '/cashflow' : '/projects');
    };

    handleAuthCallback();
  }, [navigate]);

  return <div>Processing authentication...</div>;
};

export default AuthCallback;
