import React, { useState, useEffect } from 'react';

const PercentageSlider = ({ percentages, setPercentages }) => {
  const [dragging, setDragging] = useState(null);
  const [startX, setStartX] = useState(0);
  const [startPercentages, setStartPercentages] = useState([]);

  const handleStart = (index, clientX) => {
    setDragging(index);
    setStartX(clientX);
    setStartPercentages([...percentages]);
  };

  const handleMove = (clientX) => {
    if (dragging === null) return;

    const sliderRect = document.getElementById('percentage-slider').getBoundingClientRect();
    const diff = clientX - startX;
    const percentageDiff = Math.round((diff / sliderRect.width) * 100 / 5) * 5;

    let newPercentages = [...startPercentages];
    newPercentages[dragging] = Math.max(0, Math.min(100, newPercentages[dragging] + percentageDiff));
    newPercentages[dragging + 1] = Math.max(0, Math.min(100, newPercentages[dragging + 1] - percentageDiff));

    const total = newPercentages.reduce((a, b) => a + b, 0);
    if (total !== 100) {
      const diff = 100 - total;
      const indexToAdjust = dragging === 0 ? 2 : 0;
      newPercentages[indexToAdjust] += diff;
    }

    setPercentages(newPercentages);
  };

  const handleEnd = () => {
    setDragging(null);
  };

  useEffect(() => {
    const handleMouseMove = (e) => handleMove(e.clientX);
    const handleTouchMove = (e) => handleMove(e.touches[0].clientX);

    if (dragging !== null) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('mouseup', handleEnd);
      document.addEventListener('touchend', handleEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('mouseup', handleEnd);
      document.removeEventListener('touchend', handleEnd);
    };
  }, [dragging]);

  return (
    <div id="percentage-slider" className="relative h-8 bg-gray-200 rounded-full overflow-hidden w-full">
      <div
        className="absolute top-0 left-0 h-full bg-blue-300"
        style={{ width: `${percentages[0]}%` }}
      />
      <div
        className="absolute top-0 h-full bg-blue-500"
        style={{ left: `${percentages[0]}%`, width: `${percentages[1]}%` }}
      />
      <div
        className="absolute top-0 right-0 h-full bg-blue-700"
        style={{ width: `${percentages[2]}%` }}
      />
      <div
        className="absolute top-0 left-0 h-full w-4 bg-white cursor-ew-resize"
        style={{ left: `calc(${percentages[0]}% - 8px)` }}
        onMouseDown={(e) => handleStart(0, e.clientX)}
        onTouchStart={(e) => handleStart(0, e.touches[0].clientX)}
      />
      <div
        className="absolute top-0 h-full w-4 bg-white cursor-ew-resize"
        style={{ left: `calc(${percentages[0] + percentages[1]}% - 8px)` }}
        onMouseDown={(e) => handleStart(1, e.clientX)}
        onTouchStart={(e) => handleStart(1, e.touches[0].clientX)}
      />
    </div>
  );
};

export default PercentageSlider;