import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ChevronDownIcon } from '@heroicons/react/24/solid';
import { Home, ChevronRight } from 'lucide-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ProjectCard from '../components/ProjectCard';
import Loader from '../components/Loader';

const statusClasses = {
  'Inbox': 'bg-gray-400 text-white',
  'Shaping': 'bg-yellow-500 text-black',
  'Dev Ready': 'bg-gray-600 text-white',
  'In Progress': 'bg-orange-500 text-white',
  'In Review': 'bg-blue-500 text-white',
  'Completed': 'bg-green-500 text-white',
  'Blocked': 'bg-red-500 text-white',
};

const FilterDropdown = ({ options, selectedOptions, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleOption = (option) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(s => s !== option)
      : [...selectedOptions, option];
    onChange(newSelectedOptions);
  };

  const selectAll = () => {
    onChange([...options]);
  };

  const selectNone = () => {
    onChange([]);
  };

  const selectOnly = (option) => {
    onChange([option]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const allSelected = selectedOptions.length === options.length;

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-10"
          onClick={() => setIsOpen(!isOpen)}
        >
          {allSelected ? placeholder : `${selectedOptions.length} selected`}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1" role="menu" aria-orientation="vertical">
            <div className="px-4 py-2 text-sm text-gray-700 border-b border-gray-200 flex justify-center space-x-4">
              <button onClick={selectAll} className="text-blue-600 hover:underline">Select All</button>
              <button onClick={selectNone} className="text-blue-600 hover:underline">Select None</button>
            </div>
            {options.map((option) => (
              <div key={option} className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer">
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => toggleOption(option)}
                  className="mr-2"
                />
                {option}
                <button onClick={() => selectOnly(option)} className="ml-auto text-gray-400 hover:underline">only</button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const Column = ({ status, projects, onClientClick, filterParams }) => {
  const getStatusClasses = (status) => {
    const baseClasses = "px-3 py-1 rounded-md ";
    return baseClasses + (statusClasses[status] || 'bg-gray-400 text-white');
  };

  return (
    <div className="p-2 rounded-lg min-w-[340px]">
      <h3 className="text-lg font-semibold mb-4 flex items-center">
        <span className={getStatusClasses(status)}>
          {status}
        </span>
        <span className="ml-2 text-sm font-medium px-2 py-1 rounded-full bg-gray-200 text-gray-800">
          {projects.length}
        </span>
      </h3>
      <Droppable droppableId={status}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className="min-h-[200px] space-y-4 p-2 rounded-lg"
          >
            {projects.map((project, index) => (
              <ProjectCard
                key={project.id}
                project={project}
                index={index}
                onClientClick={onClientClick}
                filterParams={filterParams}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

const Breadcrumbs = ({ clientName, resetFilters }) => {
  const pages = [
    { name: 'Projects', href: '/projects', current: !clientName },
    ...(clientName ? [{ name: clientName, href: `/projects?client=${encodeURIComponent(clientName)}`, current: true }] : []),
  ];

  return (
    <nav aria-label="Breadcrumb" className="flex mb-4">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <Link 
              to="/projects" 
              className="text-gray-400 hover:text-gray-500"
              onClick={(e) => {
                e.preventDefault();
                resetFilters();
              }}
            >
              <Home className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Projects</span>
            </Link>
          </div>
        </li>
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              <ChevronRight className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              {page.current ? (
                <span className="ml-4 text-sm font-medium text-gray-500" aria-current="page">
                  {page.name}
                </span>
              ) : (
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  onClick={(e) => {
                    e.preventDefault();
                    resetFilters();
                  }}
                >
                  {page.name}
                </Link>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    statuses: [],
    clients: [],
    appetites: []
  });

  const [availableFilters, setAvailableFilters] = useState({
    statuses: [],
    clients: [],
    appetites: []
  });

  const navigate = useNavigate();
  const location = useLocation();

  const [clientFilter, setClientFilter] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    fetchProjects();
  }, []); // Fetch projects only once on component mount

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const clientParam = searchParams.get('client');
    if (clientParam) {
      setClientFilter(clientParam);
      setFilters(prev => ({ ...prev, clients: [clientParam] }));
    } else {
      setClientFilter(null);
      setFilters(prev => ({ ...prev, clients: [] }));
    }
  }, [location.search]);

  const fetchProjects = async () => {
    setLoading(true);
    setError(null);
    try {
      console.log('Fetching projects...');
      const apiUrl = `${process.env.REACT_APP_API_URL}/api/projects`;
      console.log('API URL:', apiUrl);
      const response = await axios.get(apiUrl, { timeout: 60000 });
      console.log('Projects fetched successfully:', response.data);
      setProjects(response.data);
      
      // Extract unique values for filters
      const statuses = [...new Set(response.data.map(project => project.status))];
      const clients = [...new Set(response.data.map(project => project.client?.name).filter(Boolean))];
      const appetites = [...new Set(response.data.map(project => project.appetite).filter(Boolean))];

      setAvailableFilters({
        statuses,
        clients,
        appetites
      });

    } catch (err) {
      console.error('Error fetching projects:', err);
      if (err.code === 'ECONNABORTED') {
        setError('Request timed out. Please try again later.');
      } else {
        setError(`Failed to fetch projects: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (filterType, selectedOptions) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterType]: selectedOptions
    }));
  };

  const handleClientClick = (e, clientName) => {
    e.preventDefault(); // Prevent the default link behavior
    e.stopPropagation(); // Prevent the click from bubbling up to the card
    navigate(`/projects?client=${encodeURIComponent(clientName)}`);
  };

  const filteredProjects = useMemo(() => {
    return projects.filter(project => {
      return (
        (filters.statuses.length === 0 || filters.statuses.includes(project.status)) &&
        (filters.clients.length === 0 || (project.client && filters.clients.includes(project.client.name))) &&
        (filters.appetites.length === 0 || filters.appetites.includes(project.appetite))
      );
    });
  }, [projects, filters]);

  const onDragEnd = (result) => {
    // Implement drag and drop logic here
    console.log(result);
  };

  const resetFilters = () => {
    setFilters({
      statuses: [],
      clients: [],
      appetites: []
    });
    setClientFilter(null);
    navigate('/projects');
  };

  const filterParams = useMemo(() => {
    const params = new URLSearchParams();
    if (filters.statuses.length) params.append('statuses', filters.statuses.join(','));
    if (filters.clients.length) params.append('clients', filters.clients.join(','));
    if (filters.appetites.length) params.append('appetites', filters.appetites.join(','));
    return params.toString() ? `?${params.toString()}` : '';
  }, [filters]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-shrink-0">
        <div className="px-4 sm:px-8 py-8">
          <div className="mb-6 w-full">
            <Breadcrumbs clientName={clientFilter} resetFilters={resetFilters} />
          </div>
          
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 w-full">
            <h1 className="text-2xl font-bold mb-4 sm:mb-0">Projects</h1>
            <div className="flex flex-wrap items-center gap-4">
              <FilterDropdown
                options={availableFilters.statuses}
                selectedOptions={filters.statuses}
                onChange={(selected) => handleFilterChange('statuses', selected)}
                placeholder="Status"
              />
              <FilterDropdown
                options={availableFilters.clients}
                selectedOptions={filters.clients}
                onChange={(selected) => handleFilterChange('clients', selected)}
                placeholder="Client"
              />
              <FilterDropdown
                options={availableFilters.appetites}
                selectedOptions={filters.appetites}
                onChange={(selected) => handleFilterChange('appetites', selected)}
                placeholder="Appetite"
              />
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => console.log('New Project button clicked')}
              >
                New Project
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-grow overflow-x-auto">
        <DragDropContext onDragEnd={onDragEnd}>
          {loading ? (
            <Loader />
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : (
            <div className="flex space-x-4 px-4 sm:px-8 pb-8 w-full">
              {availableFilters.statuses.map(status => (
                <Column
                  key={status}
                  status={status}
                  projects={filteredProjects.filter(project => project.status === status)}
                  onClientClick={handleClientClick}
                  filterParams={filterParams}
                />
              ))}
            </div>
          )}
        </DragDropContext>
      </div>
    </div>
  );
};

export default Projects;